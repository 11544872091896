import { googleData } from '../../../Services/utilities';
import EligibleEmployees from './EligibleEmployees';
import AverageSalary from './AverageSalary';
import EmployerPaidParticipantFees from './EmployerPaidParticipantFees';
import StepQuestion from './StepQuestion';
import EnableEmployerContribution from './EnableEmployerContribution';
import EmployerContributionMatch from './EmployerContributionMatch';
import AutoDeferralType from './AutoDeferralType';
import PlanEffectiveDate from './PlanEffectiveDate';
import ShareForm from './ShareForm';
import AutoEnrollment from './AutoEnrollment';
import SafeHarborQuestions from './SafeHarborQuestions';
import HardshipWithdrawals from './HardshipWithdrawals';
import VestingSchedule from './VestingSchedule';
import GrandfatherDate from './GrandfatherDate';
import BusinessAging from './BusinessAging';
import UnionEmployees from './UnionEmployees';
import {
  autoDeferralTypePreviousStep,
  autoEnrollmentPreviousStep,
  businessAgeNextStep,
  grandfatherDateNextStep,
  isAutoEnrollmentOptional,
  isEnrollmentOptionalAndIsEnrolled,
  participantFeesPreviousStep,
} from '../../../Services/stepFlowUtilities';

export const defaultComponents = {
  EligibleEmployees,
  UnionEmployees,
  AverageSalary,
  EmployerPaidParticipantFees,
  StepQuestion,
  EnableEmployerContribution,
  EmployerContributionMatch,
  AutoDeferralType,
  PlanEffectiveDate,
  ShareForm,
  AutoEnrollment,
  SafeHarborQuestions,
  HardshipWithdrawals,
  VestingSchedule,
  GrandfatherDate,
  BusinessAging,
};

const step1PreviousStep = (formData, advisorIa, splitComp, role, hashId) => {
  if (role === 'advisor') {
    // if (splitComp && formData.planDesign.advisors.length > 1)
    //   return 'splitComp';
    // if (advisorIa) return 'compensation';
    return 'isCurrentPrincipalPlan';
  }
  if (formData.planDesign.questions.hasFp.value) {
    return hashId !== '' ? 'employerQuestions' : 'typeOfOrganization';
  }
  return 'roleSelection';
};

const step4NextStep = (formData, role) => {
  const { hasFp, hasTpa } = formData.planDesign.questions;
  if (hasTpa.value || (hasFp.value && role !== 'advisor')) return 'step_7';
  return 'step_5';
};

export const defaultFormSteps = (
  hashId,
  formData,
  lastLocation,
  role,
  advisorInfo,
  history,
  goToReviewPlan,
  employerContributionMatch,
  employerPaidParticipantFees,
  autoEnrollment,
  safeHarbor,
  isFirmBrokerSplitComp,
  isAdvisorIa,
) => [
  {
    hashId,
    hasSidebar: false,
    planOwner: formData.planOwner,
    createdDate: formData.createdDate,
    updatedDate: formData.updatedDate,
    key: 'begin',
    component: 'LetsBegin',
    googleData: googleData('plans'),
    destination: () => {
      if (lastLocation) {
        if (lastLocation.pathname === '/financial-professionals')
          return 'advisorCheckList';
      }
      if (hashId !== '') {
        return 'returnRoleSelection';
      }
      return 'roleSelection';
    },
  },
  {
    role,
    hashId,
    history,
    key: 'roleSelection',
    questionId: 'roleSelection',
    component: 'RoleSelection',
    hasSidebar: false,
    previousStep: 'begin',
    googleData: googleData('role_selection'),
  },
  {
    role,
    hashId,
    advisorInfo,
    history,
    key: 'returnRoleSelection',
    questionId: 'returnRoleSelection',
    component: 'ReturnRoleSelection',
    hasSidebar: false,
    previousStep: 'begin',
    googleData: googleData('return_role_selection'),
  },
  {
    role,
    hashId,
    history,
    key: 'userInfo',
    questionId: 'userInfo',
    component: 'UserInfo',
    hasSidebar: false,
    previousStep: 'returnRoleSelection',
    advisorInfo,
    googleData: googleData('user_info'),
  },
  {
    role,
    goToReviewPlan,
    history,
    key: 'shareForm',
    hasSidebar: false,
    component: 'ShareForm',
    previousStep: 'returnRoleSelection',
    googleData: googleData('shareForm'),
  },
  {
    role,
    hashId,
    key: 'step_1',
    showTracker: true,
    questionId: 'numberOfEmployees',
    component: 'EligibleEmployees',
    hasSidebar: false,
    singleSelection: true,
    destination: (isEligible, cb) => {
      if (!isEligible) {
        history.replace({
          pathname: '/larger-group',
        });
      }
      cb('step_2', 'forward');
    },
    goToReviewPlan,
    previousStep: step1PreviousStep(
      formData,
      isAdvisorIa,
      isFirmBrokerSplitComp,
      role,
      hashId,
    ),
    googleData: googleData(`eligible_employees_${role}`),
  },
  {
    role,
    hashId,
    key: 'step_2',
    showTracker: true,
    questionId: 'hasUnionEmployees',
    component: 'UnionEmployees',
    hasSidebar: false,
    singleSelection: true,
    destination: (isEligible, cb) => {
      cb('step_3', 'forward');
    },
    goToReviewPlan,
    previousStep: 'step_1',
    googleData: googleData(`union_employees_${role}`),
  },
  {
    role,
    key: 'step_3',
    showTracker: true,
    singleSelection: true,
    questionId: 'averageSalary',
    component: 'AverageSalary',
    hasSidebar: true,
    destination: () => 'step_4',
    goToReviewPlan,
    previousStep: 'step_2',
    googleData: googleData(`annual_salary_${role}`),
  },
  {
    role,
    key: 'step_4',
    showTracker: true,
    singleSelection: true,
    questionId: 'serviceEligibility',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: () => step4NextStep(formData, role),
    goToReviewPlan,
    previousStep: 'step_3',
    googleData: googleData(`when_enrolled_${role}`),
  },
  {
    role,
    key: 'step_5',
    showTracker: true,
    singleSelection: true,
    questionId: 'hasGrandfatherDate',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: (value) => (value === 'yes' ? 'step_6' : 'step_7'),
    goToReviewPlan,
    previousStep: 'step_4',
    googleData: googleData(`has_grandfather_date_${role}`),
  },
  {
    role,
    key: 'step_6',
    showTracker: true,
    singleSelection: true,
    questionId: 'grandfatherDateHasCustomDate',
    component: 'GrandfatherDate',
    hasSidebar: false,
    destination: () => grandfatherDateNextStep(formData, role),
    goToReviewPlan,
    previousStep: 'step_5',
    googleData: googleData(`grandfather_date_${role}`),
  },
  {
    role,
    key: 'step_7',
    showTracker: true,
    singleSelection: true,
    questionId: 'businessAging',
    component: 'BusinessAging',
    hasSidebar: true,
    destination: (value) => businessAgeNextStep(formData, value),
    goToReviewPlan,
    previousStep: () => {
      const { hasGrandfatherDate } = formData.planDesign.questions;

      return hasGrandfatherDate.value === 'yes' ? 'step_6' : 'step_5';
    },
  },
  {
    role,
    key: 'step_8',
    showTracker: true,
    singleSelection: true,
    questionId: 'autoEnrollment',
    component: 'AutoEnrollment',
    hasSidebar: true,
    destination: (value) => {
      if (
        isAutoEnrollmentOptional(formData.planDesign.questions) &&
        value === 'yes'
      )
        return 'step_10';

      return value === 'yes' ? 'step_9' : 'step_11';
    },
    goToReviewPlan,
    previousStep: autoEnrollmentPreviousStep(formData, role),
    // googleData: googleData(`when_enrolled_${role}`),
  },
  {
    role,
    key: 'step_9',
    showTracker: true,
    singleSelection: true,
    questionId: 'autoDeferralType',
    component: 'AutoDeferralType',
    hasSidebar: true,
    destination: (value) => {
      if (isEnrollmentOptionalAndIsEnrolled(formData.planDesign.questions))
        return 'step_11';

      return value === 'FLAT' ? 'step_11' : 'step_10';
    },
    goToReviewPlan,
    previousStep: autoDeferralTypePreviousStep(formData),
    googleData: googleData(`deferral_type_${role}`),
  },
  {
    role,
    key: 'step_10',
    showTracker: true,
    singleSelection: true,
    questionId: 'minimumAutoDeferralPercentage',
    component: 'EnableEmployerContribution',
    hasSidebar: true,
    destination: () => {
      if (isEnrollmentOptionalAndIsEnrolled(formData.planDesign.questions))
        return 'step_9';

      return 'step_11';
    },
    goToReviewPlan,
    previousStep: isEnrollmentOptionalAndIsEnrolled(
      formData.planDesign.questions,
    )
      ? 'step_8'
      : 'step_9',
    googleData: googleData(`default_contribution_${role}`),
  },
  {
    role,
    key: 'step_11',
    showTracker: true,
    singleSelection: true,
    questionId: 'employerPaidParticipantFees',
    component: 'EmployerPaidParticipantFees',
    hasSidebar: true,
    destination: () => 'step_12',
    goToReviewPlan,
    previousStep: () =>
      participantFeesPreviousStep(formData.planDesign.questions),
    googleData: googleData(`pay_participant_fee_${role}`),
  },
  {
    role,
    key: 'step_12',
    showTracker: true,
    goToReviewPlan,
    questionId: 'employerContributionMatch',
    component: 'EmployerContributionMatch',
    hasSidebar: true,
    singleSelection: true,
    destination: (value) => (value === 'yes' ? 'step_13' : 'step_17'),
    previousStep: 'step_11',
    googleData: googleData(`contribution_match_${role}`),
  },
  {
    role,
    key: 'step_13',
    showTracker: true,
    mergedSelections: true,
    questionId: 'safeHarbor',
    component: 'SafeHarborQuestions',
    hasSidebar: true,
    destination: (value) => (value === 'nonSafeHarbor' ? 'step_15' : 'step_14'),
    goToReviewPlan,
    previousStep: 'step_12',
    googleData: googleData(`safe_harbor_${role}`),
  },
  {
    role,
    key: 'step_14',
    showTracker: true,
    questionId: 'stretchSafeHarbor',
    component: 'StepQuestion',
    hasSidebar: true,
    mergedSelections: true,
    goToReviewPlan,
    destination: () => 'step_17',
    previousStep: 'step_13',
    googleData: googleData(`stretch_safe_harbor_${role}`),
  },
  {
    role,
    key: 'step_15',
    showTracker: true,
    singleSelection: true,
    questionId: 'employerMatch',
    component: 'StepQuestion',
    hasSidebar: true,
    destination: () =>
      employerContributionMatch.value === 'yes' &&
      safeHarbor.value === 'nonSafeHarbor'
        ? 'step_16'
        : 'step_17',
    goToReviewPlan,
    previousStep: 'step_13',
  },
  {
    role,
    key: 'step_16',
    showTracker: true,
    singleSelection: true,
    questionId: 'vestingSchedule',
    component: 'VestingSchedule',
    hasSidebar: true,
    destination: () => 'step_17',
    goToReviewPlan,
    previousStep: 'step_15',
  },
  {
    role,
    key: 'step_17',
    showTracker: true,
    singleSelection: true,
    questionId: 'hardshipWithdrawals',
    component: 'HardshipWithdrawals',
    hasSidebar: true,
    destination: () => 'step_18',
    goToReviewPlan,
    previousStep: prevStepHardship(employerContributionMatch, safeHarbor),
  },
  {
    role,
    key: 'step_18',
    showTracker: true,
    questionId: 'planEffectiveDate',
    component: 'PlanEffectiveDate',
    hasSidebar: true,
    singleSelection: true,
    goToReviewPlan,
    previousStep: 'step_17',
    googleData: googleData(`plan_effective_date_${role}`),
  },
];

const prevStepHardship = (employerContributionMatch, safeHarbor) => {
  if (employerContributionMatch.value === 'no') {
    return 'step_12';
  }
  if (safeHarbor.value === 'nonSafeHarbor') {
    return 'step_16';
  }
  return 'step_14';
};
