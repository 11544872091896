export const originalAutoDeferralType = {
  id: 15,
  stepName: 'step_9',
  type: 'radio_button',
  name: 'autoDeferralType',
  heading: '',
  content: '',
  user: {
    employer: {
      label: 'How would you like to handle employee contribution increases?',
      infoLabel:
        'Automatic contribution increases help make saving for retirement easy for employees.',
    },
    advisor: {
      label:
        'How would the business owner like to handle employee contribution increases?',
      infoLabel:
        'Automatic contribution increases help make saving for retirement easy for employees.',
    },
  },
  options: [
    {
      id: 1,
      user: {},
      label: 'Auto increase',
      info: 'By default, employee contributions automatically increase 1% each year up to 10%, helping employees save more for retirement. <em>Employees can opt out anytime.</em>',
      value: 'ESCALATING',
      valueFormatted: 'Auto increase',
      checked: false,
      icon: '',
      customStyle: '',
    },
    {
      id: 2,
      value: 'FLAT',
      user: {},
      label: 'Employee decides',
      info: 'Employees contribute only the default percentage of their income each year until they make a change.',
      valueFormatted: 'Employee decides',
      checked: false,
      icon: '',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Employee contribution increase',
  reviewScreenTitle: 'Employee contribution increase',
  description: '',
  optionDescription: `
    <p class="font-italic mb-0"><strong>You selected from these EMPLOYEE options:</strong></p>
    <ul class="font-italic">
      <li>
        <strong>Auto increase</strong> — By default, employee contributions automatically increase 1% each year up to 10%, helping employees save more for retirement. Employees can opt out anytime.
      </li>
      <li>
        <strong>Fixed</strong> — Employees contribute only the default percentage of their income each year until they make a change
      </li>
    </ul>
    `,
};
