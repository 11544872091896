import React from 'react';
import PropTypes from 'prop-types';
import { Question } from '../../../../common/FormWithSteps';

const MaxAutoEscalateQuestions = ({
  primaryQuestion,
  secondaryQuestion,
  tertiaryQuestion,
  role,
  handleQuestionChange,
}) => {
  const autoEscalate = secondaryQuestion.value === 'yes';

  return (
    <div className="row">
      <div className="col-md-9 mx-auto">
        <Question
          type={primaryQuestion.type}
          question={primaryQuestion}
          handleQuestionChange={handleQuestionChange}
        />
        <Question
          type={secondaryQuestion.type}
          question={secondaryQuestion}
          handleQuestionChange={handleQuestionChange}
          labelClass="fs-h4 fw-bold text-center"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
        />
        {autoEscalate && (
          <Question
            role={role}
            type={tertiaryQuestion.type}
            question={{ ...tertiaryQuestion, min: 10 }}
            handleQuestionChange={handleQuestionChange}
            labelClass="fs-h4 fw-bold text-center"
          />
        )}
      </div>
    </div>
  );
};

MaxAutoEscalateQuestions.propTypes = {
  primaryQuestion: PropTypes.object.isRequired,
  secondaryQuestion: PropTypes.object.isRequired,
  tertiaryQuestion: PropTypes.object.isRequired,
  role: PropTypes.string,
  handleQuestionChange: PropTypes.func.isRequired,
};

export default MaxAutoEscalateQuestions;
