import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import FormDataRedux, {
  selectRole,
  selectAdvisorQuestions,
  selectHashId,
  selectIsFirmBrokerSplitComp,
} from '../../../Redux/FormDataRedux';
import FormWithSteps, { Step } from '../../../common/FormWithSteps';
import CostEstimator from '../../../common/FormWithSteps/PlanOutLine/CostEstimator';

// Steps
import LetsBegin from './LetsBegin';
import RoleSelection from './RoleSelection';
import ReturnRoleSelection from './ReturnRoleSelection';
import EmployerQuestions from './EmployerQuestions';
import EmployerCheckList from '../../SetupPlan/EmployerQuestions/EmployerCheckList';
import ShareForm from './ShareForm';

import AdvisorClientInfo from './AdvisorSteps/AdvisorClientInfo';
import AdvisorFirmInfo from './AdvisorSteps/AdvisorFirmInfo';

import StepQuestion from '../../SetupPlan/DefaultPlan/StepQuestion';
import EligibleEmployees from './EligibleEmployees';
import UnionEmployees from '../../SetupPlan/DefaultPlan/UnionEmployees';
import AverageSalary from '../../SetupPlan/DefaultPlan/AverageSalary';
import EmployerPaidParticipantFees from '../../SetupPlan/DefaultPlan/EmployerPaidParticipantFees';
import EnableEmployerContribution from '../../SetupPlan/DefaultPlan/EnableEmployerContribution';
import EmployerContributionMatch from '../../SetupPlan/DefaultPlan/EmployerContributionMatch';
import AutoDeferralType from '../../SetupPlan/DefaultPlan/AutoDeferralType';
import SafeHarborQuestions from '../../SetupPlan/DefaultPlan/SafeHarborQuestions';
import StretchSafeHarbor from '../../SetupPlan/DefaultPlan/StretchSafeHarbor';
import PlanEffectiveDate from '../../SetupPlan/DefaultPlan/PlanEffectiveDate';
import { HardshipWithdrawals } from '../../SetupPlan/DefaultPlan/HardshipWithdrawals';
import AutoEnrollment from '../../SetupPlan/DefaultPlan/AutoEnrollment';
import { VestingSchedule } from '../../SetupPlan/DefaultPlan/VestingSchedule';
import { GrandfatherDate } from '../../SetupPlan/DefaultPlan/GrandfatherDate';
import BusinessAging from '../../SetupPlan/DefaultPlan/BusinessAging';
import {
  autoDeferralTypePreviousStep,
  businessAgeNextStep,
  isAutoEnrollmentOptional,
  isEnrollmentOptionalAndIsEnrolled,
  participantFeesPreviousStep,
} from '../../../Services/stepFlowUtilities';

const prevStepHardship = (employerContributionMatch, safeHarbor) => {
  if (employerContributionMatch.value === 'no') {
    return 'step_12';
  }
  if (safeHarbor.value === 'nonSafeHarbor') {
    return 'step_16';
  }
  return 'step_14';
};

export const SetupPlan = ({
  formData,
  role,
  putActiveStep,
  putQuestionById,
  history,
  authenticityToken = '',
  putBrokerEid,
  hashId = '',
  resetData = () => {},
  advisorInfo = [],
  putCrd,
}) => {
  const { safeHarbor, employerContributionMatch } =
    formData.planDesign.questions;
  const [notificationIndicator, setNotificationIndicator] = useState(false);
  const lastLocation = useLastLocation();
  const handleClose = () => {
    if (lastLocation) {
      history.goBack();
    } else {
      history.push('/onedigital');
    }
  };
  const goToReviewPlan = () => {
    history.push('/onedigital/review-plan');
  };

  const googleData = (key) => ({
    event: 'OneDigitalPlanDesignView',
    PlanDesignView: key,
  });

  const formSteps = [
    {
      hashId,
      hasSidebar: false,
      planOwner: formData.planOwner,
      createdDate: formData.createdDate,
      updatedDate: formData.updatedDate,
      key: 'begin',
      component: LetsBegin,
      destination: () => {
        if (hashId !== '') {
          return 'returnRoleSelection';
        }
        return 'roleSelection';
      },
      googleData: googleData('plans'),
    },
    {
      role,
      hashId,
      history,
      key: 'roleSelection',
      questionId: 'roleSelection',
      component: RoleSelection,
      hasSidebar: false,
      previousStep: 'begin',
      googleData: googleData('role_selection'),
    },
    {
      role,
      hashId,
      advisorInfo,
      history,
      key: 'returnRoleSelection',
      questionId: 'returnRoleSelection',
      component: ReturnRoleSelection,
      hasSidebar: false,
      previousStep: 'begin',
      googleData: googleData('return_role_selection'),
    },
    {
      role,
      key: 'employerCheckList',
      hasSidebar: false,
      component: EmployerCheckList,
      destination: () => 'employerQuestions',
      previousStep: 'begin',
      googleData: googleData('checklist_onedigital'),
    },
    {
      authenticityToken,
      formData,
      role,
      hashId,
      key: 'employerQuestions',
      hasSidebar: false,
      component: EmployerQuestions,
      previousStep: 'employerCheckList',
      destination: (validAdvisorInfo) =>
        validAdvisorInfo ? 'step_1' : 'shareForm',
    },
    {
      authenticityToken,
      formData,
      role,
      hashId,
      key: 'shareForm',
      hasSidebar: false,
      component: ShareForm,
      previousStep: 'employerQuestions',
      googleData: googleData('share_form_onedigital'),
    },
    {
      hashId,
      role,
      key: 'advisorClientInfo',
      hasSidebar: false,
      putCrd,
      component: AdvisorClientInfo,
      previousStep: 'begin',
      destination: () => 'advisorFirmInfo',
      googleData: googleData('advisor_client_info'),
    },
    {
      role,
      key: 'advisorFirmInfo',
      hasSidebar: false,
      component: AdvisorFirmInfo,
      destination: () => 'step_1',
      previousStep: 'advisorClientInfo',
      googleData: googleData('firm_info_advisor'),
    },

    {
      role,
      hashId,
      key: 'step_1',
      showTracker: true,
      questionId: 'numberOfEmployees',
      component: EligibleEmployees,
      hasSidebar: false,
      singleSelection: true,
      destination: (isEligible, cb) => {
        if (!isEligible) {
          history.replace({
            pathname: '/larger-group',
          });
        }
        cb('step_2', 'forward');
      },
      goToReviewPlan,
      previousStep: () => {
        if (role === 'onedigital') {
          return 'employerFirmInfo';
        }
        return 'advisorFirmInfo';
      },
      googleData: googleData(`eligible_employees_${role}`),
    },
    {
      role,
      hashId,
      key: 'step_2',
      showTracker: true,
      questionId: 'hasUnionEmployees',
      component: UnionEmployees,
      hasSidebar: false,
      singleSelection: true,
      destination: (isEligible, cb) => {
        cb('step_3', 'forward');
      },
      goToReviewPlan,
      previousStep: 'step_1',
      googleData: googleData(`union_employees_${role}`),
    },
    {
      role,
      key: 'step_3',
      showTracker: true,
      singleSelection: true,
      questionId: 'averageSalary',
      component: AverageSalary,
      hasSidebar: true,
      destination: () => 'step_4',
      goToReviewPlan,
      previousStep: 'step_2',
      googleData: googleData(`annual_salary_${role}`),
    },
    {
      role,
      key: 'step_4',
      showTracker: true,
      singleSelection: true,
      questionId: 'serviceEligibility',
      component: StepQuestion,
      hasSidebar: true,
      destination: () => 'step_5',
      goToReviewPlan,
      previousStep: 'step_3',
      googleData: googleData(`when_enrolled_${role}`),
    },
    {
      role,
      key: 'step_5',
      showTracker: true,
      singleSelection: true,
      questionId: 'hasGrandfatherDate',
      component: StepQuestion,
      hasSidebar: true,
      destination: (value) => (value === 'yes' ? 'step_6' : 'step_7'),
      goToReviewPlan,
      previousStep: 'step_4',
      googleData: googleData(`has_grandfather_date_${role}`),
    },
    {
      role,
      key: 'step_6',
      showTracker: true,
      singleSelection: true,
      questionId: 'grandfatherDateHasCustomDate',
      component: GrandfatherDate,
      hasSidebar: true,
      destination: () => 'step_7',
      goToReviewPlan,
      previousStep: 'step_5',
      googleData: googleData(`grandfather_date_${role}`),
    },
    {
      role,
      key: 'step_7',
      showTracker: true,
      singleSelection: true,
      questionId: 'businessAging',
      component: BusinessAging,
      hasSidebar: true,
      destination: (value) => businessAgeNextStep(formData, value),
      goToReviewPlan,
      previousStep: () => {
        const { hasGrandfatherDate } = formData.planDesign.questions;

        return hasGrandfatherDate.value === 'yes' ? 'step_6' : 'step_5';
      },
      googleData: googleData(`business_age_${role}`),
    },
    {
      role,
      key: 'step_8',
      showTracker: true,
      singleSelection: true,
      questionId: 'autoEnrollment',
      component: AutoEnrollment,
      hasSidebar: true,
      destination: (value) => {
        if (
          isAutoEnrollmentOptional(formData.planDesign.questions) &&
          value === 'yes'
        )
          return 'step_10';

        return value === 'yes' ? 'step_9' : 'step_11';
      },
      goToReviewPlan,
      previousStep: 'step_7',
      googleData: googleData(`auto_enrollment_${role}`),
    },
    {
      role,
      key: 'step_9',
      showTracker: true,
      singleSelection: true,
      questionId: 'autoDeferralType',
      component: AutoDeferralType,
      hasSidebar: true,
      destination: (value) => {
        if (isEnrollmentOptionalAndIsEnrolled(formData.planDesign.questions))
          return 'step_11';

        return value === 'FLAT' ? 'step_11' : 'step_10';
      },
      goToReviewPlan,
      previousStep: autoDeferralTypePreviousStep(formData),
      googleData: googleData(`deferral_type_${role}`),
    },
    {
      role,
      key: 'step_10',
      showTracker: true,
      singleSelection: true,
      questionId: 'minimumAutoDeferralPercentage',
      component: EnableEmployerContribution,
      hasSidebar: true,
      destination: () => {
        if (isEnrollmentOptionalAndIsEnrolled(formData.planDesign.questions))
          return 'step_9';

        return 'step_11';
      },
      goToReviewPlan,
      previousStep: isEnrollmentOptionalAndIsEnrolled(
        formData.planDesign.questions,
      )
        ? 'step_8'
        : 'step_9',
      googleData: googleData(`default_contribution_${role}`),
    },
    {
      role,
      key: 'step_11',
      showTracker: true,
      singleSelection: true,
      questionId: 'employerPaidParticipantFees',
      component: EmployerPaidParticipantFees,
      hasSidebar: true,
      destination: () => 'step_12',
      goToReviewPlan,
      previousStep: () =>
        participantFeesPreviousStep(formData.planDesign.questions),
      googleData: googleData(`pay_participant_fee_${role}`),
    },
    {
      role,
      key: 'step_12',
      showTracker: true,
      goToReviewPlan,
      questionId: 'employerContributionMatch',
      component: EmployerContributionMatch,
      hasSidebar: true,
      singleSelection: true,
      destination: (value) => (value === 'yes' ? 'step_13' : 'step_17'),
      previousStep: 'step_11',
      googleData: googleData(`contribution_match_${role}`),
    },
    {
      role,
      key: 'step_13',
      showTracker: true,
      mergedSelections: true,
      questionId: 'safeHarbor',
      component: SafeHarborQuestions,
      hasSidebar: true,
      destination: (value) =>
        value === 'nonSafeHarbor' ? 'step_15' : 'step_14',
      goToReviewPlan,
      previousStep: 'step_12',
      googleData: googleData(`safe_harbor_${role}`),
    },
    {
      role,
      key: 'step_14',
      showTracker: true,
      mergedSelections: true,
      questionId: 'stretchSafeHarbor',
      component: StretchSafeHarbor,
      hasSidebar: true,
      destination: () => 'step_17',
      goToReviewPlan,
      previousStep: 'step_13',
      googleData: googleData(`stretch_safe_harbor_${role}`),
    },
    {
      role,
      key: 'step_15',
      showTracker: true,
      mergedSelections: true,
      questionId: 'employerMatch',
      component: StepQuestion,
      hasSidebar: true,
      destination: () =>
        employerContributionMatch.value === 'yes' &&
        safeHarbor.value === 'nonSafeHarbor'
          ? 'step_16'
          : 'step_17',
      goToReviewPlan,
      previousStep: 'step_13',
    },
    {
      role,
      key: 'step_16',
      showTracker: true,
      singleSelection: true,
      questionId: 'vestingSchedule',
      component: VestingSchedule,
      hasSidebar: true,
      destination: () => 'step_17',
      goToReviewPlan,
      previousStep: 'step_15',
    },
    {
      role,
      key: 'step_17',
      showTracker: true,
      singleSelection: true,
      questionId: 'hardshipWithdrawals',
      component: HardshipWithdrawals,
      hasSidebar: true,
      destination: () => 'step_18',
      goToReviewPlan,
      previousStep: prevStepHardship(employerContributionMatch, safeHarbor),
    },
    {
      role,
      key: 'step_18',
      showTracker: true,
      questionId: 'planEffectiveDate',
      component: PlanEffectiveDate,
      hasSidebar: true,
      singleSelection: true,
      goToReviewPlan,
      previousStep: 'step_17',
      googleData: googleData(`plan_effective_date_${role}`),
    },
  ];

  return (
    <FormWithSteps
      formInReview={formData.planDesign.formInReview}
      startAtStep={formData.planDesign.startAtStep}
      formId={formData.planDesign.formId}
      activeStep={formData.planDesign.activeStep}
      questions={formData.planDesign.questions}
      putActiveStep={putActiveStep}
      putQuestionById={putQuestionById}
      handleClose={handleClose}
      formData={formData}
      resetData={resetData}
      putBrokerEid={putBrokerEid}
      notificationIndicator={notificationIndicator}
      setNotificationIndicator={setNotificationIndicator}
      hashId={hashId}
      costEstimator={CostEstimator}
    >
      {formSteps.map((step) => (
        <Step name={step.key} {...step} />
      ))}
    </FormWithSteps>
  );
};

SetupPlan.propTypes = {
  formData: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  putQuestionById: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  hashId: PropTypes.string,
  putBrokerEid: PropTypes.func,
  resetData: PropTypes.func,
  advisorInfo: PropTypes.array,
  putCrd: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  formData: store.formData,
  role: selectRole(store),
  advisors: store.formData.planDesign.advisors,
  advisorInfo: selectAdvisorQuestions(store),
  hashId: selectHashId(store),
  isFirmBrokerSplitComp: selectIsFirmBrokerSplitComp(store),
});
const mapDispatchToProps = (dispatch) => ({
  putActiveStep: (formId, updateStep) => {
    dispatch(FormDataRedux.putActiveStep(formId, updateStep));
  },
  putQuestionById: (
    formId,
    questionId,
    value,
    hasError,
    dirty,
    valueFormatted,
    disabled,
  ) => {
    dispatch(
      FormDataRedux.putQuestionById(
        formId,
        questionId,
        value,
        hasError,
        dirty,
        valueFormatted,
        disabled,
      ),
    );
  },
  resetData: (formId) => {
    dispatch(FormDataRedux.resetData(formId));
  },
  putBrokerEid: (eid, index) => {
    dispatch(FormDataRedux.putBrokerEid(eid, index));
  },
  putCrd: (obj, index) => {
    dispatch(FormDataRedux.putCrd(obj, index));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupPlan);
