export const employerMatch = {
  id: 14,
  stepName: 'step_15',
  type: 'slider',
  name: 'employerMatch',
  tooltipNumber: 4,
  step: 1,
  labelClass: 'fs-form-q fw-bold text-center py-3',
  tooltipInfo: `For 4% safe harbor match could be a better option (allows you to bypass some IRS compliance tests)`,
  user: {
    employer: {
      label:
        'How much of your employees’ contributions would you like to match?',
      infoLabel:
        'You will have the option to match your employees’ contributions dollar for dollar, up to 10% of pay. <i>For example, if an employee contributes 5%, your match would be 5% of pay.</i>',
    },
    advisor: {
      label:
        'How much of the employees’ contributions would the business owner like to match?',
      infoLabel:
        'You will have the option to match your employees’ contributions dollar for dollar, up to 10% of pay. <i>For example, if an employee contributes 5%, your match would be 5% of pay.</i>',
    },
  },
  format: 'percentage',
  required: true,
  dirty: false,
  min: 1,
  thousandSeperator: false,
  max: 10,
  defaultValue: 3,
  sideCardTitle: 'Employer contribution match',
  reviewScreenTitle: 'Employer contribution match',
  disabled: false,
  valueFormatted: '3%',
  value: 3,
  hasConditional: false,
  hasError: false,
  description: '',
};
