import {
  getAgeRequirement,
  getEntry,
  getEntryDateRequirement,
  getRequiredAge,
  getServiceEligibility,
  getServiceRequirement,
  getSourceId,
  getSourceName,
  getTpaFirmName,
  getVestingScheduleFormula,
  getVestingScheduleID,
  shouldSetPlanSourceFrequency,
  sourcesGrandfatherDate,
} from './ParadigmUtilities';

export const paradigmPackagerUnbundled = async (
  planDesign,
  tpaParadigmPackager,
  tpas,
) => {
  const {
    autoEnrollment = { value: '', valueFormatted: '' },
    autoEnrollmentPlan = { value: '', valueFormatted: '' },
    allowLoans = { value: '', valueFormatted: '' },
    minAgeReqEligibility = { value: '', valueFormatted: '' },
    hasAutoEscalation = { value: '', valueFormatted: '' },
    maxAutoEscalatePercentage = { value: '', valueFormatted: '' },
    minimumAutoDeferralPercentage = { value: '', valueFormatted: '' },
    minServiceReqEligibility = { value: '', valueFormatted: '' },
    entryDatesPlanParticipation = { value: '', valueFormatted: '' },
    hardshipWithdrawals = { value: '', valueFormatted: '' },
    permissibleWithdrawals = { value: '', valueFormatted: '' },
    numberOfPermissibleDays = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  const { insuranceGroupType, employerStateCode } = planDesign.questions;

  const paradigmPlanTPA = {
    requiredAge: {
      month: 0,
      year: await getRequiredAge(minAgeReqEligibility),
    },
    serviceEligibility: await getServiceEligibility(minServiceReqEligibility),
    entry: await getEntry(entryDatesPlanParticipation),
    permissibleWithdrawal: permissibleWithdrawals.value === 'yes',
  };

  if (permissibleWithdrawals.value === 'yes') {
    paradigmPlanTPA.permissibleWithdrawalDays = parseInt(
      numberOfPermissibleDays.value,
      10,
    );
  }

  if (tpas[1] !== undefined && tpas[1].questions.tpaEmail.value !== '') {
    paradigmPlanTPA.productCode = 'simplyrtmttpa';
    paradigmPlanTPA.allowsLoans = allowLoans.value === 'yes';
    paradigmPlanTPA.allowsHardshipWithdrawals =
      hardshipWithdrawals.value === 'yes';

    if (autoEnrollment.value === 'yes') {
      paradigmPlanTPA.autoEnrollment = 'yes';
      paradigmPlanTPA.autoEnrollmentType = autoEnrollmentPlan.valueFormatted;
      if (hasAutoEscalation.value === 'yes') {
        paradigmPlanTPA.autoDeferralType = 'ESCALATING';
        paradigmPlanTPA.yearlyAutoDeferralPercentageIncrease = 1;
        paradigmPlanTPA.maximumAutoDeferralPercentage =
          maxAutoEscalatePercentage?.value ||
          minimumAutoDeferralPercentage.value;
      } else {
        paradigmPlanTPA.autoDeferralType = 'FLAT';
        paradigmPlanTPA.yearlyAutoDeferralPercentageIncrease = 0;
        paradigmPlanTPA.maximumAutoDeferralPercentage = 0;
      }
      paradigmPlanTPA.minimumAutoDeferralPercentage =
        minimumAutoDeferralPercentage.value;
    } else {
      paradigmPlanTPA.autoEnrollment = 'no';
      paradigmPlanTPA.autoEnrollmentType = null;
      paradigmPlanTPA.autoDeferralType = null;
      paradigmPlanTPA.minimumAutoDeferralPercentage = null;
      paradigmPlanTPA.maximumAutoDeferralPercentage = null;
      paradigmPlanTPA.yearlyAutoDeferralPercentageIncrease = null;
    }
  }

  if (employerStateCode.value === 'TX') {
    paradigmPlanTPA.attributes = [
      {
        key: 'INSURANCE_GROUP_TYPE',
        value: parseInt(insuranceGroupType?.valueFormatted, 10),
      },
    ];
  }

  return paradigmPlanTPA;
};

export const paradigmPlanAddSourcesUnbundled = async (
  planDesign,
  tpaParadigmPackager,
  vestingSchedule,
) => {
  const { contributionRequirements } = planDesign.questions;
  const {
    planUsingSafeHarbor = { value: '', valueFormatted: '' },
    autoEnrollmentPlan = { value: '', valueFormatted: '' },
    hasDiscretionaryEmployerMatch = { value: '', valueFormatted: '' },
    discretionarySharingContributions = { value: '', valueFormatted: '' },
    whichSafeHarborQaca = { value: '', valueFormatted: '' },
    whichSafeHarbor = { value: '', valueFormatted: '' },
    offerRothContributions = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  const addSources = [];
  let sourceIndex = 0;

  addSources[sourceIndex] = {
    id: 1,
    name: 'Employee Pretax Contribution',
    sourceType: 'DEFERRAL',
    contributorType: 'EMPLOYEE',
    vestingId: null,
    requiredAge: null,
    entry: null,
    serviceEligibility: null,
    grandfatherDate: sourcesGrandfatherDate(
      planDesign.questions,
      tpaParadigmPackager,
    ),
  };
  sourceIndex += 1;

  if (offerRothContributions.value === 'yes') {
    addSources[sourceIndex] = {
      id: 2,
      name: 'Employee Roth Contribution',
      sourceType: 'DEFERRAL',
      contributorType: 'EMPLOYEE',
      vestingId: null,
      requiredAge: null,
      entry: null,
      serviceEligibility: null,
      grandfatherDate: sourcesGrandfatherDate(
        planDesign.questions,
        tpaParadigmPackager,
      ),
    };
    sourceIndex += 1;
  }

  // If DiscretionarySharingContributions then addSource
  if (
    discretionarySharingContributions !== undefined &&
    discretionarySharingContributions.value === 'yes'
  ) {
    addSources[sourceIndex] = await tpaEmployeeProfitSharingSource(
      contributionRequirements,
      tpaParadigmPackager,
      planDesign,
    );
    sourceIndex += 1;
  }

  // If discretionaryEmployerMatch then add source
  if (
    hasDiscretionaryEmployerMatch !== undefined &&
    hasDiscretionaryEmployerMatch.value === 'yes'
  ) {
    addSources[sourceIndex] =
      await tpaDiscretionaryEmployerMatchContributionSource(
        contributionRequirements,
        tpaParadigmPackager,
        planDesign,
      );
    sourceIndex += 1;
  }

  if (planUsingSafeHarbor.value === '' || planUsingSafeHarbor.value === 'yes') {
    const sourceId = await getSourceId(
      whichSafeHarborQaca,
      whichSafeHarbor,
      autoEnrollmentPlan,
    );
    const sourceName = await getSourceName(
      whichSafeHarborQaca,
      whichSafeHarbor,
      autoEnrollmentPlan,
    );
    addSources[sourceIndex] = await tpaSafeHarborAddSource(
      sourceId,
      sourceName,
      sourceIndex,
      tpaParadigmPackager,
      vestingSchedule,
      planDesign,
    );
  }

  return addSources;
};

export const tpaSafeHarborAddSource = async (
  sourceId,
  sourceName,
  sourceIndex,
  tpaParadigmPackager,
  vestingSchedule,
  planDesign,
) => {
  const {
    autoEnrollment = { value: '', valueFormatted: '' },
    autoEnrollmentPlan = { value: '', valueFormatted: '' },
    minAgeReqEligibility = { value: '', valueFormatted: '' },
    minServiceReqEligibility = { value: '', valueFormatted: '' },
    entryDatesPlanParticipation = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;
  const addSource = {
    id: sourceId,
    name: sourceName,
    sourceType: 'SAFE',
    contributorType: 'EMPLOYER',
    requiredAge: {
      month: 0,
      year: await getRequiredAge(minAgeReqEligibility),
    },
    serviceEligibility: await getServiceEligibility(minServiceReqEligibility),
    grandfatherDate: sourcesGrandfatherDate(
      planDesign.questions,
      tpaParadigmPackager,
    ),
    ...(shouldSetPlanSourceFrequency(sourceId) && {
      planSourceFrequency: 'PAYROLL',
    }),
    entry: await getEntry(entryDatesPlanParticipation),
  };
  if (
    (autoEnrollment.value === 'yes' && autoEnrollmentPlan.value !== 'qaca') ||
    autoEnrollment.value === 'no'
  ) {
    addSource.vestingId = 1;
  } else if (vestingSchedule !== undefined) {
    addSource.vestingId = await getVestingScheduleID(vestingSchedule);
  }
  addSource.formula =
    addSource.vestingId !== 1
      ? await getVestingScheduleFormula(vestingSchedule)
      : '100';
  return addSource;
};

const tpaEmployeeProfitSharingSource = async (
  contributionRequirements,
  tpaParadigmPackager,
  planDesign,
) => {
  const {
    minAgeReqEligibility = { value: '', valueFormatted: '' },
    minAgeReqDiscretionaryProfitContributions = {
      value: '',
      valueFormatted: '',
    },
    minServiceReqEligibility = { value: '', valueFormatted: '' },
    minServiceReqDiscretionaryProfit = { value: '', valueFormatted: '' },
    entryDatesPlanParticipation = { value: '', valueFormatted: '' },
    entryDateDiscretionaryProfitContribution = {
      value: '',
      valueFormatted: '',
    },
    vestingScheduleProfit = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  let discretionarySharingAge;
  let discretionarySharingService;
  let discretionarySharingEntry;

  // if plan requirements are uniform then set
  if (contributionRequirements.value === 'yes') {
    discretionarySharingAge = minAgeReqEligibility;
    discretionarySharingService = minServiceReqEligibility;
    discretionarySharingEntry = entryDatesPlanParticipation;
  }
  // otherwise set source requirements based on overarching plan requirements
  else {
    discretionarySharingAge = await getAgeRequirement(
      minAgeReqEligibility,
      minAgeReqDiscretionaryProfitContributions,
    );
    discretionarySharingService = await getServiceRequirement(
      minServiceReqEligibility,
      minServiceReqDiscretionaryProfit,
    );
    discretionarySharingEntry = await getEntryDateRequirement(
      entryDatesPlanParticipation,
      entryDateDiscretionaryProfitContribution,
    );
  }

  const addSource = {
    id: 10,
    name: 'Employee Profit Sharing',
    sourceType: 'PROFIT',
    contributorType: 'EMPLOYER',
    entry: await getEntry(discretionarySharingEntry),
    serviceEligibility: await getServiceEligibility(
      discretionarySharingService,
    ),
    grandfatherDate: sourcesGrandfatherDate(
      planDesign.questions,
      tpaParadigmPackager,
    ),
    requiredAge: {
      month: 0,
      year: await getRequiredAge(discretionarySharingAge),
    },
    vestingId: await getVestingScheduleID(vestingScheduleProfit),
    formula: await getVestingScheduleFormula(vestingScheduleProfit),
  };
  return addSource;
};

const tpaDiscretionaryEmployerMatchContributionSource = async (
  contributionRequirements,
  tpaParadigmPackager,
  planDesign,
) => {
  const {
    minAgeReqEligibility = { value: '', valueFormatted: '' },
    minAgeReqDiscretionaryMatchContributions = {
      value: '',
      valueFormatted: '',
    },
    minServiceReqEligibility = { value: '', valueFormatted: '' },
    minServiceReqDiscretionaryMatchingContribution = {
      value: '',
      valueFormatted: '',
    },
    entryDatesPlanParticipation = { value: '', valueFormatted: '' },
    entryDatesDiscretionaryMatching = { value: '', valueFormatted: '' },
    vestingScheduleMatch = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  let discretionaryMatchAge;
  let discretionaryMatchService;
  let discretionaryMatchEntryDate;

  // If plan is subject to same requirements then set
  if (contributionRequirements.value === 'yes') {
    discretionaryMatchAge = minAgeReqEligibility;
    discretionaryMatchService = minServiceReqEligibility;
    discretionaryMatchEntryDate = entryDatesPlanParticipation;
  }
  // otherwise set source requirements based on overarching plan requirements
  else {
    discretionaryMatchAge = await getAgeRequirement(
      minAgeReqEligibility,
      minAgeReqDiscretionaryMatchContributions,
    );
    discretionaryMatchService = await getServiceRequirement(
      minServiceReqEligibility,
      minServiceReqDiscretionaryMatchingContribution,
    );
    discretionaryMatchEntryDate = await getEntryDateRequirement(
      entryDatesPlanParticipation,
      entryDatesDiscretionaryMatching,
    );
  }

  const addSource = {
    id: 11,
    name: 'Employer Match Contributions',
    sourceType: 'MATCH',
    contributorType: 'EMPLOYER',
    serviceEligibility: await getServiceEligibility(discretionaryMatchService),
    entry: await getEntry(discretionaryMatchEntryDate),
    grandfatherDate: sourcesGrandfatherDate(
      planDesign.questions,
      tpaParadigmPackager,
    ),
    requiredAge: {
      month: 0,
      year: await getRequiredAge(discretionaryMatchAge),
    },
    vestingId: await getVestingScheduleID(vestingScheduleMatch),
    formula: await getVestingScheduleFormula(vestingScheduleMatch),
  };
  return addSource;
};

export const setTpaInfoParadigmRegistration = async (
  planDesign,
  tpas,
  authenticityToken,
) => {
  const { employerIdentificationNumber, tpaFirmName, tpaFirmNameUnlisted } =
    planDesign.questions;
  const { tpaFirstName, tpaLastName, tpaEmail, tpaPhone } = tpas[1].questions;

  const tpaInfo = {
    firmName: getTpaFirmName(tpaFirmName, tpaFirmNameUnlisted),
    ein: employerIdentificationNumber.value,
    firstName: tpaFirstName.value,
    lastName: tpaLastName.value,
    email: tpaEmail.value,
    phone: tpaPhone.value,
    address1: null,
    address2: null,
    city: null,
    zip: null,
    authenticity_token: authenticityToken,
  };

  return tpaInfo;
};
