export const permissibleWithdrawalsHelper = (
  questions,
  tpaParadigmPackager,
) => {
  const {
    hasTpa = { value: '', valueFormatted: '' },
    autoEnrollment: defaultPlanAutoEnrollment = {
      value: '',
      valueFormatted: '',
    },
    businessAging = { value: '', valueFormatted: '' },
  } = questions;
  const {
    autoEnrollment = { value: '', valueFormatted: '' },
    permissibleWithdrawals = { value: '', valueFormatted: '' },
    numberOfPermissibleDays = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  const eligibleEmployeeCount = parseInt(questions.numberOfEmployees.value, 10);

  if (hasTpa.value) {
    if (autoEnrollment.value === 'no') return null;
    if (permissibleWithdrawals.value === 'yes') {
      return {
        permissibleWithdrawal: true,
        permissibleWithdrawalDays: parseInt(numberOfPermissibleDays.value, 10),
        autoEnrollApplication: 'ALL',
      };
    }
    return {
      permissibleWithdrawal: false,
      permissibleWithdrawalDays: null,
      autoEnrollApplication: 'ALL',
    };
  }

  if (
    eligibleEmployeeCount < 11 ||
    businessAging.value === 'Less than 3 years'
  ) {
    if (defaultPlanAutoEnrollment.value === 'no') return null;
    return {
      permissibleWithdrawal: false,
      permissibleWithdrawalDays: null,
      autoEnrollApplication: 'ALL',
    };
  }
  if (
    eligibleEmployeeCount >= 11 &&
    businessAging.value === 'More than 3 years'
  ) {
    return {
      permissibleWithdrawal: true,
      permissibleWithdrawalDays: 30,
      autoEnrollApplication: 'ALL',
    };
  }
  return null;
};
