export const numberOfPermissibleDays = {
  id: 'numberOfPermissibleDays',
  stepName: 'a_step_11_permissible_2',
  type: 'number_field',
  name: 'numberOfPermissibleDays',
  paradigmAlias: 'numberOfPermissibleDays',
  hasInfo: true,
  infoLabel: '',
  user: 'tpa',
  label:
    'How many days do employees have to initiate a permissible withdrawal request?',
  heading: 'Number of permissible days',
  content:
    'Businesses can give their employees the option to request permissible withdrawals between 30 to 90 days after the date the first default elective deferral is made on behalf of the participant.',
  placeholder: 'Number of permissible days',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  validateRule: '',
  hasError: false,
  sideCardTitle: 'Permissible withdrawal limit',
  reviewScreenTitle: 'Permissible withdrawal limit',
  description:
    'Businesses can give their employees the option to request permissible withdrawals between 30 to 90 days.',
};
