export const employerContributionMatch = {
  id: 12,
  stepName: 'step_12',
  type: 'radio_condensed',
  name: 'employerContributionMatch',
  user: {
    employer: {
      label:
        'As the business owner, are you okay with making matching contributions?',
      infoLabel:
        'Business owners have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible. Learn more about tax deductions at <a href="https://www.irs.gov/" target="_blank">IRS.gov</a>. With Simply Retirement by Principal<sup>®</sup>, employers also have profit-sharing flexibility, which provides employers the option to contribute company profits back to employees’ 401(k) plan accounts.',
    },
    advisor: {
      label: 'Would the business owner like to make matching contributions?',
      infoLabel:
        'Business owners have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible. Learn more about tax deductions at <a href="https://www.irs.gov/" target="_blank">IRS.gov</a>. With Simply Retirement by Principal<sup>®</sup>, employers also have profit-sharing flexibility, which provides employers the option to contribute company profits back to employees’ 401(k) plan accounts.',
    },
  },
  abandonProcessOnFalse: false,
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'Not Contributing',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Contribution details',
  reviewScreenTitle: 'Matching contribution details',
  description:
    'Employers have the option to match employee contributions. This not only provides a benefit to employees, but also helps encourage them to participate in the retirement plan. Matching contributions are tax deductible (see IRS.gov for more information). There are two ways an employer can contribute — safe harbor and non-safe harbor. With safe harbor, the business owner contribute more money, but you can bypass some of the compliance testing required by the IRS. With non-safe harbor, you have the option to contribute less, but cannot bypass compliance testing required by the IRS. Compliance testing ensures that highly compensated employees don’t benefit more than others from the plan.',
};
