const hasTpaOrFpAndIsEmployer = (formData, role) => {
  const { hasFp, hasTpa } = formData.planDesign.questions;

  return hasTpa.value || (hasFp.value && role !== 'advisor');
};

export const grandfatherDateNextStep = (formData, role) => {
  if (hasTpaOrFpAndIsEmployer(formData, role)) return 'step_8';
  return 'step_7';
};

export const businessAgeNextStep = (formData, value) => {
  const { numberOfEmployees } = formData.planDesign.questions;

  if (parseInt(numberOfEmployees.value, 10) <= 10) return 'step_8';

  if (value === 'More than 3 years')
    // Need to reorder steps below a bit
    return 'step_9';

  return 'step_8';
};

export const autoEnrollmentPreviousStep = (formData, role) => {
  const { hasGrandfatherDate } = formData.planDesign.questions;
  if (hasTpaOrFpAndIsEmployer(formData, role)) {
    if (hasGrandfatherDate.value === 'yes') {
      return 'step_6';
    }
    return 'step_5';
  }
  return 'step_7';
};

export const autoDeferralTypePreviousStep = (formData) => {
  const { questions } = formData.planDesign;

  if (!isAutoEnrollmentOptional(questions)) return 'step_7';

  return isEnrollmentOptionalAndIsEnrolled(questions) ? 'step_10' : 'step_8';
};

export const isAutoEnrollmentOptional = (questions) => {
  const { numberOfEmployees, businessAging } = questions;

  return (
    parseInt(numberOfEmployees.value, 10) <= 10 ||
    businessAging.value !== 'More than 3 years'
  );
};

export const isEnrollmentOptionalAndIsEnrolled = (questions) => {
  const isEnrolled = questions.autoEnrollment.value;
  return isAutoEnrollmentOptional(questions) && isEnrolled;
};

export const participantFeesPreviousStep = (questions) => {
  const { autoDeferralType, autoEnrollment } = questions;
  if (autoEnrollment.value === 'no') return 'step_8';
  if (isEnrollmentOptionalAndIsEnrolled(questions)) return 'step_9';

  return autoDeferralType.value === 'FLAT' ? 'step_9' : 'step_10';
};
