export const autoDeferralType = {
  id: 15,
  stepName: 'step_9',
  type: 'radio_button',
  name: 'autoDeferralType',
  heading:
    'Auto-enrollment requirement for start-up plans (as an Eligible Automatic Contribution Arrangement, or EACA):',
  content: `
    <p>
        Required for newly established 401(k) and 403(b) plans with some exceptions. The default contribution rate must be at least 3%, but not more than 10%, plus an automatic contribution increase of 1% per year up to a maximum of at least 10%, but not more than 15%. (For plan years prior to 2025, the maximum cap is 10%.)
    </p> 
    <p>
        Exemptions: SIMPLE plans, existing plans in place prior to 12/29/2022, church plans, governmental plans, businesses/organizations that have been in existence for less than three years, employers with 10 or fewer employees (one year grace period when reaching 11+ employees).
    </p>`,
  user: {
    employer: {
      label: 'Please select an automatic enrollment option',
      infoLabel:
        "Based on SECURE 2.0 Act, your plan is required to have Automatic Enrollment. Please select a default percentage that all eligible employees will start contributing when they are automatically enrolled in the plan. Don't worry; employees can change this amount or opt out at any time.",
    },
    advisor: {
      label:
        'How would the business owner like to handle employee contribution increases?',
      infoLabel:
        'Automatic contribution increases help make saving for retirement easy for your employees.',
    },
  },
  options: [
    {
      id: 1,
      user: {},
      label: '3 - 9% deferral with auto escalation to 10%',
      info: 'Pre-Tax Deferral-Increasing 1% annually up to 10%',
      value: 'ESCALATING',
      valueFormatted: 'Auto increase',
      checked: false,
      icon: '',
      customStyle: '',
    },
    {
      id: 2,
      value: 'FLAT',
      user: {},
      label: '10% deferral without auto escalation',
      info: 'Pre-Tax Deferral',
      valueFormatted: 'Employee decides',
      checked: false,
      icon: '',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Employee contribution increase',
  reviewScreenTitle: 'Employee contribution increase',
  description:
    "Business owners can choose to have their employees' contributions remain fixed unless they change them, or auto-escalate 1% each year up to 10% (ACA or EACA auto enrollment option) or 15% (QACA auto enrollment option). Auto escalation can help make it simpler for employees to save more for retirement.",
  optionDescription: `
    <p class="font-italic mb-0"><strong>You selected from these EMPLOYEE options:</strong></p>
    <ul class="font-italic">
      <li>
        <strong>Auto increase</strong> — By default, employee contributions automatically increase 1% each year up to 10%, helping employees save more for retirement. Employees can opt out anytime.
      </li>
      <li>
        <strong>Fixed</strong> — Employees contribute only the default percentage of their income each year until they make a change
      </li>
    </ul>
    `,
};
