import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  StepControls,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import { isFormValid } from '../../../Redux/FormDataRedux';

export const PermissibleWithdrawals = ({
  role,
  active,
  googleData,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  isValid,
}) => {
  const { value, dirty, hasError } = questions[questionId];
  const disabled = () =>
    !dirty || value === '' || value === undefined || hasError;
  const validate = !!(dirty && hasError);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    if (updateValue === 'no') {
      updateQuestionById('numberOfPermissibleDays', '', false, false, '');
      updateQuestionById('b_numberOfPermissibleDays', '', false, false, '');
    }
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            type={questions[questionId].type}
            labelClass="fs-form-q fw-bold text-center mb-4"
            inputClass="srp-form-input form-control"
            radioWrapper="srp-form-toggle"
            thousandSeparator
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
            hasInfo={!!questions[questionId].heading}
          />
          {validate && (
            <p className="fs-p lh-125 text-danger text-center">
              Question is required
            </p>
          )}
        </div>
      </div>
      <StepControls
        formInReview={isValid && formInReview}
        goToReviewPlan={goToReviewPlan}
        disableReviewBtn={!isValid}
        disabledNextBtn={disabled()}
        clickNextBtn={() => {
          updateActiveStep(destination(value), 'forward');
        }}
      />
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

PermissibleWithdrawals.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
  googleData: PropTypes.object,
  questionId: PropTypes.string,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissibleWithdrawals);
