import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAdvisorQuestions,
} from '../../../../../Redux/FormDataRedux';
import ModalRedux from '../../../../../Redux/ModalRedux';
import ToastRedux from '../../../../../Redux/ToastRedux';
import {
  NextStepBtn,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../../common/FormWithSteps';
import Header from '../Header';
import ClientQuestions from './ClientQuestions';
import {
  pushGoogleDataLayer,
  checkForErrors,
} from '../../../../../common/FormWithSteps/Utilities';
import ConfirmModal from '../../../../../common/Modal/ConfirmModal';

import API from '../../../../../Services/api';

const ClientInfo = ({
  authenticityToken,
  active,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  updateQuestion,
  role,
  hashId,
  putPlanURL,
  advisorInfo,
  googleData,
  putApplicationPanelInfo,
  createModal,
  destroyModal,
  formData,
  putInsuranceAgent,
  putInsuranceAgentNpn,
  addToast,
}) => {
  const {
    tpaLogo,
    companyName,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    employerStateCode,
    hasFp,
    hasTpa,
  } = questions;
  const tpaValidQuestions = [
    sponsorFirstName,
    sponsorLastName,
    companyName,
    sponsorEmail,
    employerStateCode,
    hasFp,
  ];

  const [tpaLogoFiles, setTpaLogoFiles] = useState([]);
  useEffect(() => {
    saveLogo();
  }, [tpaLogoFiles]);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
  };

  const handleQuestionChangeLogo = (updateValue = {}) => {
    setTpaLogoFiles(updateValue);

    const fileMetadata = updateValue.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
    }));

    updateQuestion({
      name: tpaLogo.name,
      value: fileMetadata,
      valueFormatted: updateValue[0]?.name || '',
      hasError: updateValue.length === 0,
    });
  };

  const api = API.create();

  const saveLogo = async () => {
    if (tpaLogoFiles.length === 0) return;

    const formDataLogo = new FormData();

    formDataLogo.set('email', sponsorEmail.value);
    formDataLogo.set('company_name', companyName.value);
    formDataLogo.set('authenticity_token', authenticityToken);
    formDataLogo.set('logo', tpaLogoFiles[0]);
    if (hashId) {
      formDataLogo.set('hashId', hashId);
    }
    try {
      const response = await api.savePlan(formDataLogo);
      putPlanURL(null, response.hashId);
      updateQuestion({
        name: tpaLogo.name,
        value: response.logo_url,
      });
    } catch (error) {
      addToast('Error uploading file:', error);
    }
  };

  const nextStepAction = () => {
    const nextAction = () => {
      destroyModal();
      updateQuestion({
        name: hasTpa.name,
        value: true,
        valueFormatted: 'Yes',
        hasError: false,
        dirty: true,
      });
      saveLogo();
      updateActiveStep(destination(hasFp.value), 'forward');
    };

    createModal(
      <ConfirmModal destroyModal={destroyModal} nextAction={nextAction} />,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid--sitewidth h-100">
      <Header step={2} />
      <ClientQuestions
        questions={questions}
        role={role}
        handleQuestionChange={handleQuestionChange}
        handleQuestionChangeLogo={handleQuestionChangeLogo}
        updateQuestionById={updateQuestionById}
        advisorInfo={advisorInfo}
        putApplicationPanelInfo={putApplicationPanelInfo}
        createModal={createModal}
        formData={formData}
        putInsuranceAgent={putInsuranceAgent}
        putInsuranceAgentNpn={putInsuranceAgentNpn}
      />
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <NextStepBtn
              disabled={!checkValidityOnQuestionArray(tpaValidQuestions)}
              onClick={() => nextStepAction()}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

ClientInfo.propTypes = {
  active: PropTypes.bool,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  role: PropTypes.string,
  advisorInfo: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  hashId: PropTypes.string,
  putPlanURL: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  createModal: PropTypes.func.isRequired,
  destroyModal: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  putInsuranceAgent: PropTypes.func.isRequired,
  putInsuranceAgentNpn: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  advisorInfo: selectAdvisorQuestions(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
  putPlanURL: (planURL, hashId) => {
    dispatch(FormDataRedux.putPlanURL(planURL, hashId));
  },
  createModal: (child, modalType) => {
    dispatch(ModalRedux.createModal(child, modalType));
  },
  destroyModal: () => {
    dispatch(ModalRedux.destroyModal());
  },
  putInsuranceAgent: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgent(obj, index));
  },
  putInsuranceAgentNpn: (obj, index) => {
    dispatch(FormDataRedux.putInsuranceAgentNpn(obj, index));
  },
  addToast: (text, toastType) => {
    dispatch(ToastRedux.addToast(text, toastType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
