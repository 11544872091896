export const permissibleWithdrawals = {
  id: 'permissibleWithdrawals',
  type: 'radio_condensed',
  name: 'permissibleWithdrawals',
  paradigmAlias: 'permissibleWithdrawals',
  user: 'tpa',
  stepName: 'a_step_6',
  label: 'Should this plan allow for permissible withdrawals?',
  labelClass: 'fs-form-q fw-bold mb-3 text-center',
  infoLabel: '',
  content: '',
  heading: 'What are permissible withdrawals?',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      icon: 'icon-check-o',
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      icon: 'icon-close-o',
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  hasConditional: false,
  value: '',
  hasError: false,
  sideCardTitle: 'Allows permissible withdrawals',
  reviewScreenTitle: 'Allows permissible withdrawals',
  optionDescription: '',
  description: '',
};
