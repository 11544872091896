import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../Redux/FormDataRedux';
import { asOfDate, rootRoute } from '../../Services/utilities';

import ReviewPlanControls from './ReviewPlanControls';

const getInvestmentLineup = (investmentLineup) => {
  switch (investmentLineup) {
    case 'fundLineup1':
      return {
        name: 'Wilshire 3(38) Investment Lineup 1',
        feesRange: '0.05% - 0.70%',
        custodialFees: '0.11% - 0.76%',
        asOfDate: `${asOfDate}`,
      };
    case 'fundLineup2':
      return {
        name: 'Wilshire 3(38) Investment Lineup 2',
        feesRange: '0.03% - 0.89%',
        custodialFees: '0.09% - 0.95%',
        asOfDate: `${asOfDate}`,
      };
    case 'fundLineup3':
      return {
        name: 'Wilshire 3(38) Investment Lineup 3',
        feesRange: '0.05% - 0.73%',
        custodialFees: '0.1% - 0.8%',
        asOfDate: `${asOfDate}`,
      };
    case 'fundLineupOneDigital':
      return {
        name: 'OneDigital Investment Lineup 1',
        feesRange: '0.02% - 0.8%',
        custodialFees: '0.08% - 0.86%',
        asOfDate: `${asOfDate}`,
      };
    default:
      return {
        name: 'Wilshire 3(38) Investment Lineup 1',
        feesRange: '0.05% - 0.70%',
        custodialFees: '0.11% - 0.76%',
        asOfDate: `${asOfDate}`,
      };
  }
};

const initAmntInfo = {
  hasVisibility: true,
  heading: 'Initial amount due',
  additionalInformation: `
  <p class="fs-sm">
    This one-time fee covers the setup required to administer your plan.
    Tax credits may be available to offset a portion of plan startup
    costs through the SECURE 2.0 Act. Find more
    <a href="/">information here</a> or contact your tax advisor or
    appropriate counsel for details.
  </p>
`,
};

const ongoingCostsInfo = {
  hasVisibility: true,
  heading: 'Ongoing monthly cost',
  additionalInformation: `
  <p class="fs-sm">
    Monthly recordkeeping fees cover the cost of maintaining the plan,
    including services such as:
  </p>
  <ul>
    <li>
      Ongoing recordkeeping (e.g., tracking which employees are
      participating and the amount they’ve invested in each of the plan
      funds)
    </li>
    <li>Automated participant signup and onboarding</li>
    <li>
      An online platformfor plan administration and participant account
      management
    </li>
    <li>A dedicated phone number for questions</li>
  </ul>
  <p class="fs-sm">
    Simply Retirement by Principal<small><sup>®</sup></small>
    also provides an ERISA fidelity bond to
    protect the plan’s assets as well as access to a comprehensive
    financial wellness platform that provides tools and resources for
    participants.
  </p>
  <p class="fs-sm">
    Plan sponsors will be charged a $3 one-time fee per participant to
    cover the cost of US postage for printed materials to be mailed that
    are required by the Department of Labor notifying retirement plan
    participants that related correspondence will be via electronic delivery.
    This fee is collected once eligible employees have reached their entry
    date and/or ineligible employees roll over money into their Simply
    Retirement by Principal<small><sup>®</sup></small> account.  Additional
    information on client fees can be found in Ubiquity’s Agreement for Services.
  </p>`,
};

const financialProFeeInfo = {
  hasVisibility: true,
  heading: 'Financial professional annual fee',
  additionalInformation: `<p class="fs-sm">If a business owner chooses to work with a financial professional, the financial professional may either bill the business owner directly for their fee ("paid outside the plan") or have it deducted from participants' plan assets. If the financial professional chooses to have their fee paid from plan assets, this fee will be divided equally across the number of participants and deducted quarterly from participant accounts.</p>`,
};
const tpaCompensationInfo = {
  hasVisibility: true,
  heading: 'Third party administrator annual fee',
  additionalInformation: `<p class="fs-sm">If a business owner chooses to work with a third party administrator, the TPA may bill the business owner directly for their fee (“paid outisde the plan”), have it deducted from participants’ plan assets, or a combination of both. If the TPA chooses to have their fee paid from plan assets, this fee will be divided equally across the number of participants and deducted quarterly from participant accounts. While these TPA fees will be collected on the TPA’s behalf per your direction, it is the TPA’s responsibility to disclose these fees.</p>`,
};

const oneDigitalFeeInfo = {
  hasVisibility: true,
  heading: 'Advisor annual compensation',
  additionalInformation: `<p class="fs-sm">See OneDigital 3(38) agreement for tiered advisory fee details.</p>`,
};

const investmentFeesInfo = (investmentLineup) => {
  const fee = getInvestmentLineup(investmentLineup.value).feesRange;
  const date = getInvestmentLineup(investmentLineup.value).asOfDate;

  return {
    hasVisibility: true,
    heading: 'Custodial fees and investment fees',
    additionalInformation: `
    <p class="fs-sm">
      Custodial and investment fees are charged against participating employees' accounts. Custodial fees are 0.055% and investment fees vary and range from ${fee} (as of ${date}).
    </p>
  `,
  };
};

const TableFluidRow = ({
  label,
  moreInfo,
  putApplicationPanelInfo,
  highlight = false,
  showInfo,
  children,
  className,
}) => (
  <div className={classNames('table-fluid__row', className, highlight)}>
    <div className="table-fluid__label">
      <span className="label fs-tablelabel lh-125">
        {label}
        {showInfo && (
          <button
            type="button"
            className="d-block srp-button srp-button__link fs-sm p-0"
            onClick={() => {
              putApplicationPanelInfo(moreInfo);
            }}
          >
            More info
          </button>
        )}
      </span>
    </div>
    {children}
  </div>
);

TableFluidRow.propTypes = {
  label: PropTypes.string.isRequired,
  moreInfo: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func,
  highlight: PropTypes.bool,
  showInfo: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const FinancialProfCompBusValue = ({ advisorCompensation, userRole }) => {
  if (advisorCompensation.value === 'option_1') {
    return (
      <strong className="fs-h3 text-purple d-block">
        Paid Outside the Plan
      </strong>
    );
  }
  if (userRole === 'psivet') {
    return (
      <>
        <strong className="fs-h3 text-purple d-block">$2,500</strong>
        <span className="fs-sm">(Every year for the first 3 years)</span>
      </>
    );
  }
  return <strong className="text-dark">&#8212;</strong>;
};

FinancialProfCompBusValue.propTypes = {
  advisorCompensation: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
};

const PlanTotalsSection = ({
  employerPaidParticipantFees,
  advisorCompensation,
  numberOfEmployees,
  tpaInfo,
  advisorInfo,
  advisorType = {},
  advisorPoints = {},
  advisorFee = {},
  userRole,
  savePlanStatus,
  employerInfo,
  createSavedForm,
  applicationPanelInfo,
  putApplicationPanelInfo,
  reviewPlanDisclaimer1,
  reviewPlanDisclaimer2,
  investmentLineup,
  tpaSetupFee = '',
  annualTpaFee = '',
  annualTpaPerHeadFee = '',
  feeCollectionSource = {},
  tpaCompensationType = {},
  fixedAmount = {},
  bpsAmount = {},
}) => {
  const location = useLocation();
  const showFees = tpaSetupFee || annualTpaFee || annualTpaPerHeadFee;
  const hasFp = employerInfo.find((info) => info.name === 'hasFp').value;
  const hasTpa = employerInfo.find((info) => info.name === 'hasTpa').value;
  const isInOneDigitalPath = rootRoute(location.pathname) === '/onedigital';
  const isOwner =
    employerPaidParticipantFees.valueFormatted === 'Business owner';

  const rate = () => (hasTpa ? 145 : 185);

  const numberFormate = (number) =>
    new Intl.NumberFormat('en-IN', {
      maximumSignificantDigits: 3,
    }).format(number);

  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  const renderAdvisorCompensation = () => {
    switch (advisorType.value) {
      case 'option_1':
        return (
          <>
            <strong className="fs-h3 text-purple d-block">
              {' '}
              $
              {parseFloat(
                advisorFee.value / (numberOfEmployees.value * 4),
              ).toFixed(2)}
            </strong>
            <span className="fs-sm">per employee, billed quarterly</span>
          </>
        );

      case 'option_2':
        return (
          <>
            <button
              type="button"
              className="d-block text-center srp-button p-0 mx-auto"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: reviewPlanDisclaimer1,
                });
              }}
            >
              <span className="srp-button__link fw-bold fs-h3 text-purple ">
                {(parseFloat(advisorPoints.valueFormatted) / 100).toFixed(2)}%
              </span>
              <sup style={{ top: '-1rem' }}>*</sup>
            </button>

            <span className="fs-sm">
              of plan assets/year, deducted quarterly
            </span>
          </>
        );

      default:
        return false;
    }
  };
  const renderTpaCompensation = () => {
    switch (tpaCompensationType.value) {
      case 'fixedAmount':
        return (
          <>
            <strong className="fs-h3 text-purple d-block">
              {' '}
              $
              {parseFloat(
                fixedAmount.value / (numberOfEmployees.value * 4),
              ).toFixed(2)}
            </strong>
            <span className="fs-sm">per employee, billed quarterly</span>
          </>
        );

      case 'bpsAmount':
        return (
          <>
            <button
              type="button"
              className="d-block text-center srp-button p-0 mx-auto"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  additionalInformation: reviewPlanDisclaimer1,
                });
              }}
            >
              <span className="srp-button__link fw-bold fs-h3 text-purple ">
                {(parseFloat(bpsAmount.value) / 100).toFixed(2)}%
              </span>
              <sup style={{ top: '-1rem' }}>*</sup>
            </button>

            <span className="fs-sm">
              of plan assets/year, deducted quarterly
            </span>
          </>
        );

      default:
        return false;
    }
  };

  return (
    <div>
      <h3 className="fs-h3 text-purple fw-bold">Plan totals</h3>
      <p className="fs-p text-blue">
        Quote is valid for <strong>60 days</strong> after it was created.
      </p>

      <div className="table-fluid">
        <div className="table-fluid__thead">
          <div className="table-fluid__label"></div>
          <div className="table-fluid__col">
            <strong className="heading fs-display5 text-purple">
              Business owner {userRole === 'employer' && '(you)'}
            </strong>
          </div>
          <div className="table-fluid__col">
            <strong className="heading fs-display5">
              Individual participant
            </strong>
          </div>
        </div>
        <div className="table-fluid_header__row highlight">
          <span className="table-fluid_header__label text-purple">
            Simply Retirement Fees
          </span>
        </div>
        {!hasTpa && (
          <TableFluidRow
            label="Simply Retirement setup fee"
            col2Scope="Individual participant"
            moreInfo={initAmntInfo}
            showInfo
            putApplicationPanelInfo={putApplicationPanelInfo}
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                <strong className="fs-h3 text-purple d-block">$500</strong>
                <span className="fs-sm">
                  One-time setup fee for bundled plans
                </span>
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            </div>
          </TableFluidRow>
        )}

        <div
          className="d-none d-print-block"
          dangerouslySetInnerHTML={{
            __html: initAmntInfo.additionalInformation,
          }}
        />

        <TableFluidRow
          label="Simply Retirement annual fee"
          moreInfo={ongoingCostsInfo}
          showInfo
          putApplicationPanelInfo={putApplicationPanelInfo}
        >
          <div className="table-fluid__col">
            <span className="table-col scope">Business owner</span>
            <span className="table-col value">
              <span className="fs-h3 d-block">
                <strong className="text-purple">
                  ${numberFormate(rate() * 12)}
                </strong>
              </span>
              <span className="fs-sm">
                (${numberFormate(rate() * 3)} billed quarterly)
              </span>
            </span>
          </div>
          <div className="table-fluid__col">
            <span className="table-col scope">Individual participant</span>
            <span className="table-col value">
              <strong className="text-dark">&#8212;</strong>
            </span>
          </div>
        </TableFluidRow>

        <TableFluidRow
          label="Simply Retirement annual participant fee"
          moreInfo={ongoingCostsInfo}
          showInfo
          putApplicationPanelInfo={putApplicationPanelInfo}
          className="table-fluid_last__row"
        >
          <div className="table-fluid__col">
            <span className="table-col scope">Business owner</span>

            {isOwner ? (
              <span className="table-col value">
                <span className="fs-h3 text-purple d-block">
                  <strong className="fs-h3 text-purple d-block">$72</strong>
                </span>
              </span>
            ) : (
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            )}
          </div>
          <div className="table-fluid__col">
            <span className="table-col scope">Individual participant</span>
            {isOwner ? (
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            ) : (
              <span className="table-col value">
                <span className="fs-h3 text-purple d-block">
                  <strong className="fs-h3 text-purple d-block">$72</strong>
                </span>
                <span className="fs-sm">
                  ($6 deducted from plan assets monthly)
                </span>
              </span>
            )}
          </div>
        </TableFluidRow>

        <div
          className="d-none d-print-block"
          dangerouslySetInnerHTML={{
            __html: ongoingCostsInfo.additionalInformation,
          }}
        />

        {showFees && (
          <div className="table-fluid_header__row highlight">
            <span className="table-fluid_header__label text-purple">
              TPA Fees (billed by the TPA)
            </span>
          </div>
        )}
        {showFees && (
          <TableFluidRow
            label="TPA setup cost"
            putApplicationPanelInfo={putApplicationPanelInfo}
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                <strong className="fs-h3 text-purple d-block">
                  {tpaSetupFee || '$0'}
                </strong>
                <span className="fs-sm">
                  One-time setup fee for bundled plans
                </span>
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            </div>
          </TableFluidRow>
        )}

        {showFees && (
          <TableFluidRow
            label="TPA annual cost"
            putApplicationPanelInfo={putApplicationPanelInfo}
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                <strong className="fs-h3 text-purple d-block">
                  {annualTpaFee || '$0'}
                </strong>
                <span className="fs-sm">(TPA to determine billing cycle)</span>
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            </div>
          </TableFluidRow>
        )}

        {isInOneDigitalPath && (
          <div>
            <div className="table-fluid_header__row highlight">
              <span className="table-fluid_header__label text-purple">
                OneDigital advisory fees
              </span>
            </div>
            <TableFluidRow
              label="Advisor annual compensation"
              moreInfo={oneDigitalFeeInfo}
              showInfo
              putApplicationPanelInfo={putApplicationPanelInfo}
              className="table-fluid_last__row"
            >
              <div className="table-fluid__col">
                <span className="table-col scope">Business owner</span>
                <span className="table-col value">
                  <strong className="fs-h3 text-purple d-block">$3,000</strong>
                  <span className="fs-sm">($750 billed quarterly)</span>
                </span>
              </div>
              <div className="table-fluid__col">
                <span className="table-col scope">Individual participant</span>
                <span className="table-col value">
                  <strong className="fs-h3 text-purple d-block">0.45%</strong>
                  <span className="fs-sm">
                    of plan assets/year, deducted quarterly
                  </span>
                </span>
              </div>
            </TableFluidRow>
          </div>
        )}

        {showFees && (
          <TableFluidRow
            label="TPA annual per participant cost"
            putApplicationPanelInfo={putApplicationPanelInfo}
            className="table-fluid_last__row"
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                <strong className="fs-h3 text-purple d-block">
                  {annualTpaPerHeadFee || '$0'}
                </strong>
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                <strong className="text-dark">&#8212;</strong>
              </span>
            </div>
          </TableFluidRow>
        )}

        <div className="table-fluid_header__row highlight">
          <span className="table-fluid_header__label text-purple">
            Other Fees
          </span>
        </div>

        {hasTpa && (
          <TableFluidRow
            label="TPA compensation"
            moreInfo={tpaCompensationInfo}
            showInfo
            putApplicationPanelInfo={putApplicationPanelInfo}
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                {feeCollectionSource.value === 'DIRECT' ? (
                  <strong className="fs-h3 text-purple d-block">
                    Paid Outside the Plan
                  </strong>
                ) : (
                  <strong className="text-dark">&#8212;</strong>
                )}
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                {feeCollectionSource.value === 'ASSETS' ? (
                  renderTpaCompensation()
                ) : (
                  <strong className="text-dark">&#8212;</strong>
                )}
              </span>
            </div>
          </TableFluidRow>
        )}

        {!isInOneDigitalPath && hasFp && (
          <TableFluidRow
            label="Financial professional compensation"
            moreInfo={financialProFeeInfo}
            showInfo
            putApplicationPanelInfo={putApplicationPanelInfo}
          >
            <div className="table-fluid__col">
              <span className="table-col scope">Business owner</span>
              <span className="table-col value">
                <FinancialProfCompBusValue
                  advisorCompensation={advisorCompensation}
                  userRole={userRole}
                />
              </span>
            </div>
            <div className="table-fluid__col">
              <span className="table-col scope">Individual participant</span>
              <span className="table-col value">
                {advisorCompensation.value !== 'option_1' ? (
                  renderAdvisorCompensation()
                ) : (
                  <strong className="text-dark">&#8212;</strong>
                )}
              </span>
            </div>
          </TableFluidRow>
        )}

        {hasFp && (
          <div
            className="d-none d-print-block"
            dangerouslySetInnerHTML={{
              __html: financialProFeeInfo.additionalInformation,
            }}
          />
        )}

        <TableFluidRow
          label="Custodial fees and investment fees"
          moreInfo={investmentFeesInfo(investmentLineup)}
          showInfo
          putApplicationPanelInfo={putApplicationPanelInfo}
          className="table-fluid_last__row"
        >
          <div className="table-fluid__col">
            <span className="table-col scope">Business owner</span>
            <span className="table-col value">
              <strong className="text-dark">&#8212;</strong>
            </span>
          </div>
          <div className="table-fluid__col">
            <span className="table-col scope">Individual participant</span>
            <span className="table-col value">
              <button
                type="button"
                className="d-block text-center srp-button p-0 mx-auto"
                onClick={() => {
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation: reviewPlanDisclaimer2,
                  });
                }}
              >
                <span className="srp-button__link fw-bold fs-h3 text-purple ">
                  {getInvestmentLineup(investmentLineup.value).custodialFees}
                </span>
                <sup style={{ top: '-1rem' }}>*</sup>
              </button>
              <span className="fs-sm">of plan assets/year</span>
            </span>
          </div>
        </TableFluidRow>

        <div
          className="d-none d-print-block"
          dangerouslySetInnerHTML={{
            __html: investmentFeesInfo(investmentLineup).additionalInformation,
          }}
        />
      </div>

      <div className="my-3 text-md-right text-center d-print-none">
        <ReviewPlanControls
          savePlanStatus={savePlanStatus}
          userRole={userRole}
          employerInfo={employerInfo}
          advisorInfo={advisorInfo}
          tpaInfo={tpaInfo}
          createSavedForm={createSavedForm}
        />
      </div>
    </div>
  );
};

PlanTotalsSection.propTypes = {
  userRole: PropTypes.string.isRequired,
  savePlanStatus: PropTypes.string.isRequired,
  employerInfo: PropTypes.array.isRequired,
  createSavedForm: PropTypes.func.isRequired,
  employerPaidParticipantFees: PropTypes.object.isRequired,
  advisorCompensation: PropTypes.object.isRequired,
  numberOfEmployees: PropTypes.object.isRequired,
  advisorInfo: PropTypes.array.isRequired,
  tpaInfo: PropTypes.array.isRequired,
  advisorType: PropTypes.object,
  advisorPoints: PropTypes.object,
  advisorFee: PropTypes.object,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  reviewPlanDisclaimer1: PropTypes.string.isRequired,
  reviewPlanDisclaimer2: PropTypes.string.isRequired,
  investmentLineup: PropTypes.object.isRequired,
  tpaSetupFee: PropTypes.string,
  annualTpaFee: PropTypes.string,
  annualTpaPerHeadFee: PropTypes.string,
  feeCollectionSource: PropTypes.object,
  tpaCompensationType: PropTypes.object,
  fixedAmount: PropTypes.object,
  bpsAmount: PropTypes.object,
};

const mapStateToProps = (store) => ({
  investmentLineup: store.formData.planDesign.questions.investmentLineup,
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanTotalsSection);
