import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FormDataRedux from '../../../../../Redux/FormDataRedux';
import { Question } from '../../../../../common/FormWithSteps';

const AdvisorBasisPoints = ({
  advisorFirmBroker,
  advisorPoints,
  handleQuestionChange,
  updateQuestion,
}) => {
  const { basisPointsType, basisPointsMin, basisPointsMax } =
    advisorFirmBroker.value;
  const advisorPointsFiltered = () => {
    if (advisorFirmBroker.value.maxBasisPoints?.length) {
      if (typeof advisorFirmBroker.value.maxBasisPoints === 'object') {
        return advisorFirmBroker.value.maxBasisPoints.map((option, index) => ({
          ...advisorPoints.options[0],
          label: option,
          value: `option_${index + 1}`,
          id: index,
          valueFormatted: option,
        }));
      }
      return advisorPoints.options.filter(
        (option) =>
          parseInt(option.label, 10) <=
          parseInt(advisorFirmBroker.value.maxBasisPoints || 75, 10),
      );
    }
    return advisorPoints.options;
  };

  if (basisPointsType === 'number') {
    return (
      <div
        className={classNames('form-group text-center', {
          'has-error': advisorPoints.hasError,
        })}
      >
        <label className="fs-h4 fw-bold" htmlFor={advisorPoints.name}>
          {advisorPoints.label}
        </label>
        {advisorPoints.infoLabel && (
          <div
            className="fs-p text-center mb-2"
            dangerouslySetInnerHTML={{ __html: advisorPoints.infoLabel }}
          />
        )}
        <NumericFormat
          style={{ width: 'auto', margin: 'auto' }}
          id={advisorPoints.name}
          allowNegative={false}
          className={classNames('fs-h3 srp-form-input form-control', {
            'is-invalid': advisorPoints.hasError,
            'is-dirty': advisorPoints.dirty,
          })}
          required
          name={advisorPoints.name}
          thousandSeparator
          value={advisorPoints.valueFormatted}
          onValueChange={({ floatValue, value }) => {
            updateQuestion({
              name: advisorPoints.name,
              value: floatValue,
              valueFormatted: value,
              hasError: !(
                floatValue >= basisPointsMin && floatValue <= basisPointsMax
              ),
              dirty: true,
            });
          }}
        />
        {advisorPoints.hasError && (
          <p className="text-center text-danger my-3">
            Value must be between {basisPointsMin} and {basisPointsMax}
          </p>
        )}
      </div>
    );
  }
  return (
    <Question
      type={advisorPoints.type}
      question={{ ...advisorPoints, options: advisorPointsFiltered() }}
      handleQuestionChange={handleQuestionChange}
      radioWrapper="srp-form-toggle"
      labelClass="fs-h4 fw-bold text-center"
    />
  );
};

AdvisorBasisPoints.propTypes = {
  advisorFirmBroker: PropTypes.object.isRequired,
  advisorPoints: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
});

export default connect(null, mapDispatchToProps)(AdvisorBasisPoints);
