import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';

import { Edit } from './index.styles';
import {
  disableAutoEnrollment,
  disableDeferralPercentage,
} from '../../../../common/FormWithSteps/PlanOutLine/utilities';

export const EditBtn = ({ style, handleClick }) => (
  <Edit type="button" style={style} onClick={handleClick}>
    <span className="sr-only">More info</span>
  </Edit>
);

EditBtn.propTypes = {
  handleClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export const PlanOptionSelections = ({
  question,
  goToRoleSelection,
  optionDescription = '',
  description = '',
  contributionPlanOptionQuestions = {},
  questions = {},
  applicationPanelInfo,
  putApplicationPanelInfo,
  isEditable,
  userRole = '',
  ...rest
}) => {
  const { hasFp, hasTpa } = questions;
  let editable = isEditable;
  if (question.name === 'employerPaidParticipantFees') {
    if (userRole === 'tpa') editable = false;
    if (userRole === 'employer' && hasFp.value === false) editable = true;
    if (userRole === 'advisor' && hasTpa.value) editable = true;
  }

  if (
    disableDeferralPercentage(questions, question.name) ||
    disableAutoEnrollment(questions, question.name)
  )
    editable = false;

  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  const renderContributionValues = () => (
    <div className="col-6 my-3 text-end d-md-flex justify-content-end align-items-center">
      <span className="d-inline-block">
        <span
          key={contributionPlanOptionQuestions.safeHarbor.name}
          className="d-block fs-h3 fw-bold text-purple"
        >
          {contributionPlanOptionQuestions.safeHarbor.valueFormatted ||
            contributionPlanOptionQuestions.safeHarbor.value}
        </span>
        {contributionPlanOptionQuestions.stretchSafeHarbor.dirty && (
          <span
            key={contributionPlanOptionQuestions.stretchSafeHarbor.name}
            className="d-block fs-h3 fw-bold text-purple"
          >
            {contributionPlanOptionQuestions.stretchSafeHarbor.valueFormatted ||
              contributionPlanOptionQuestions.stretchSafeHarbor.value}
          </span>
        )}
      </span>
      {editable && (
        <EditBtn
          style={{ verticalAlign: 'top', marginTop: '12px' }}
          handleClick={() => {
            goToRoleSelection(question.stepName);
          }}
        />
      )}
    </div>
  );

  const showMoreInfo = () => {
    if (question.name === 'investmentLineup') {
      return false;
    }
    if (description || optionDescription) {
      if (
        question.name === 'vestingSchedule' ||
        question.name === 'autoEnrollment' ||
        question.name === 'hardshipWithdrawals'
      ) {
        return userRole !== 'tpa';
      }
      return true;
    }
    return false;
  };

  const renderValue = () => (
    <div className="col-6 my-3 text-end d-md-flex justify-content-end align-items-center">
      <span className="fs-h3 fw-bold text-purple">
        {question.valueFormatted || question.value}
      </span>

      {editable && (
        <EditBtn
          handleClick={() => {
            goToRoleSelection(question.stepName);
          }}
        />
      )}
    </div>
  );

  const planEffectiveDateDisclaimer = () => (
    <div className="col-12">
      <div className="my-3 mb-5">
        <p className="text-muted fs-sm">
          The plan effective date can be any date that is at least 21 days or
          more from the date the purchase process is initiated.
        </p>
      </div>
    </div>
  );

  return (
    <div
      key={question.name}
      data-question={question.name}
      data-stepname={question.stepName}
      className="my-3 border-bottom"
    >
      <div className="row">
        <div className="col my-3">
          <div className="fs-tablelabel lh-125 mb-1">
            {question.reviewScreenTitle}
          </div>
          {showMoreInfo() && (
            <button
              type="button"
              className="d-block srp-button srp-button__link fs-sm p-0"
              onClick={() => {
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: question.reviewScreenTitle,
                  description,
                  additionalInformation: optionDescription,
                });
              }}
            >
              More info
            </button>
          )}
          {rest.children}
        </div>
        {question.name === 'employerContributionMatch' &&
        question.value === 'yes'
          ? renderContributionValues()
          : renderValue()}
        {question.name === 'planEffectiveDate'
          ? planEffectiveDateDisclaimer()
          : ''}
      </div>
    </div>
  );
};

PlanOptionSelections.propTypes = {
  question: PropTypes.object.isRequired,
  optionDescription: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contributionPlanOptionQuestions: PropTypes.object,
  questions: PropTypes.object,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  goToRoleSelection: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanOptionSelections);
