export const tpaSalesforceDataPack = (
  stageFlag,
  formData,
  tpaParadigmPackager,
) => {
  const {
    annualTpaPerHeadFee,
    employerIdentificationNumber,
    hasTpa,
    tpaFirmName,
    tpaFirmNameUnlisted,
    tpaSetupFee,
  } = formData.planDesign.questions;
  const {
    autoEnrollmentPlan = { value: '', valueFormatted: '' },
    hasAutoEscalation = { value: '', valueFormatted: '' },
    minAgeReqEligibility = { value: '', valueFormatted: '' },
    minimumAutoDeferralPercentage = { value: '', valueFormatted: '' },
    whichSafeHarbor = { value: '', valueFormatted: '' },
    whichSafeHarborQaca = { value: '', valueFormatted: '' },
    permissibleWithdrawals = { value: '', valueFormatted: '' },
    numberOfPermissibleDays = { value: '', valueFormatted: '' },
  } = tpaParadigmPackager;

  const primaryTPA = formData.planDesign.tpas[0];
  const secondaryTPA = formData.planDesign.tpas[1];
  const {
    tpaFirstName: { value: tpaFirstName },
    tpaLastName: { value: tpaLastName },
    tpaEmail: { value: tpaEmail },
  } = primaryTPA.questions;

  const tpaPlanSalesforceData = {
    QACA_Safe_Harbor_Vesting_Schedule__c: whichSafeHarborQaca?.value || '',
    Service_Option__c: hasTpa.value
      ? 'Simply Retirement Unbundled'
      : 'Simply Retirement Bundled',
    TPA_Annual_Per_Participant_Cost__c: hasTpa.value
      ? annualTpaPerHeadFee.value
      : null,
    TPA_EIN__c: employerIdentificationNumber.value || '',
    TPA_Firm_Name__c: tpaFirmName.dirty
      ? tpaFirmName.value.value
      : tpaFirmNameUnlisted.value,
    TPA_Included__c: hasTpa.value,
    TPA_Setup_Fee__c: hasTpa.value ? tpaSetupFee.value : null,
  };

  if (!hasTpa.value) return tpaPlanSalesforceData;

  if (permissibleWithdrawals.value === 'yes') {
    tpaPlanSalesforceData.Permissible_Withdrawal_Days__c = parseInt(
      numberOfPermissibleDays.value,
      10,
    );
  }

  if (primaryTPA.questions.tpaEmail.dirty) {
    tpaPlanSalesforceData.TPA_Sales_First_Name__c = tpaFirstName;
    tpaPlanSalesforceData.TPA_Sales_Last_Name__c = tpaLastName;
    tpaPlanSalesforceData.TPA_Sales_Email__c = tpaEmail;
  }
  if (secondaryTPA !== undefined && secondaryTPA.questions.tpaEmail.dirty) {
    tpaPlanSalesforceData.TPA_Ops_First_Name__c =
      secondaryTPA.questions.tpaFirstName.value;
    tpaPlanSalesforceData.TPA_Ops_Last_Name__c =
      secondaryTPA.questions.tpaLastName.value;
    tpaPlanSalesforceData.TPA_Ops_Email__c =
      secondaryTPA.questions.tpaEmail.value;
    tpaPlanSalesforceData.TPA_Ops_Phone__c =
      secondaryTPA.questions.tpaPhone.value;
  }

  if (stageFlag === 'New') return tpaPlanSalesforceData;

  if (autoEnrollmentPlan.value !== '') {
    tpaPlanSalesforceData.Auto_Enrollment_Deferral_Escalation_Rate__c =
      getDeferralTypeAndPercentage(
        hasAutoEscalation,
        minimumAutoDeferralPercentage,
      );
  }
  tpaPlanSalesforceData.Safe_Harbor__c = getSafeHarbor(whichSafeHarbor?.value);
  tpaPlanSalesforceData.Eligibility_Age__c = setEligibilityAge(
    minAgeReqEligibility.value,
  );

  return tpaPlanSalesforceData;
};

export const advisorSalesforceDataPack = (stageFlag, formData) => {
  const { advisorFirmBroker, firmNameFreeForm, hasFp } =
    formData.planDesign.questions;

  const { brokerType, insuranceAgentNpn, insuranceAgent, crd } =
    formData.planDesign.advisors[0].advisorCredentialVerification;

  const {
    advisorEmail: { value: advisorEmail },
    advisorFirstName: { value: advisorFirstName },
    advisorLastName: { value: advisorLastName },
    advisorPhone: { value: advisorPhone },
    advisorStateCode: { value: advisorStateCode },
    advisorZipCode: { value: zip },
    advisorNpn,
    compensation,
  } = formData.planDesign.advisors[0].questions;
  const mapCrd = hasFp.value && brokerType !== 'insuranceAgent';

  const advisorSalesforceData = {
    Advisor_Broker_Dealer__c: advisorFirmBroker.value.value || '',
    Advisor_Compensation_Amount__c: compensation.value || '',
    Advisor_CRD__c: mapCrd ? crd.value : '',
    Advisor_Included__c: hasFp.value,
    Advisor_NPN__c: advisorNpn.value || '',
    Financial_Professional_Email__c: hasFp.value ? advisorEmail : null,
    Financial_Professional_First_Name__c: hasFp.value ? advisorFirstName : null,
    Financial_Professional_Last_Name__c: hasFp.value ? advisorLastName : null,
    Financial_Professional_Phone__c: hasFp.value ? advisorPhone : null,
    Financial_Professional_State__c: hasFp.value ? advisorStateCode : null,
    Financial_Professional_Zip_Code__c: hasFp.value ? zip : null,
    Financial_Professional_Role__c: hasFp.value
      ? normalizeAdvisorRole(brokerType)
      : '',
    Firm_Name__c: hasFp.value
      ? normalizeAdvisorFirmName(brokerType, insuranceAgent, firmNameFreeForm)
      : '',
    Insurance_Agency_NPN__c:
      hasFp.value && brokerType === 'insuranceAgent' ? insuranceAgentNpn : '',
  };
  return advisorSalesforceData;
};

export const getEligibility = (eligibility) => {
  switch (eligibility) {
    case 'THREE_MONTHS':
      return 'Three Months';
    case 'TWELVE_MONTHS':
      return '1 Year';
    default:
      return 'Immediate';
  }
};

export const getSafeHarbor = (safeHarbor) => {
  switch (safeHarbor.value) {
    case '4':
      return 'Basic Match (100% of 1st 3%+50% of next 2%)';
    case '5':
      return '4% Enhanced Match';
    case 'basicMatch100to3-50toNext2':
      return 'Basic Match (100% of 1st 3%+50% of next 2%)';
    case 'enhancedMatch100to4':
      return '4% Enhanced Match';
    case 'enhancedMatch100to5':
      return '5% Enhanced Match';
    case 'enhancedMatch100to6':
      return '6% Enhanced Match';
    default:
      return 'None';
  }
};

export const getAutoEnrollmentPlan = (formData, tpaParadigmPackager) => {
  const { autoEnrollment, hasTpa } = formData.planDesign.questions;
  const { autoEnrollmentPlan = { value: '', valueFormatted: '' } } =
    tpaParadigmPackager;
  if (autoEnrollment.value === 'yes') {
    if (hasTpa.value) {
      return autoEnrollmentPlan.value === 'qaca' ? 'QACA' : 'EACA';
    }
    return 'EACA';
  }
  return 'None';
};

export const getPlanEntryFrequency = (planEntryFrequency) => {
  switch (planEntryFrequency?.value) {
    case 'dailyImmediate':
      return 'Immediate';
    case 'monthly':
      return 'Monthly';
    case 'quarterly':
      return 'Quarterly';
    case 'semiannually':
      return 'Semi-Annually';
    default:
      return '';
  }
};

export const getDeferralTypeAndPercentage = (
  autoEscalateAutoEnrolledDeferrals,
  autoDeferralPercentageStartEmployees,
) => {
  switch (autoDeferralPercentageStartEmployees.value) {
    case 3:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '3% Auto-Escalate';
      }
      return '3% Flat Deferral Rate';
    case 4:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '4% Auto-Escalate';
      }
      return '4% Flat Deferral Rate';
    case 5:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '5% Auto-Escalate';
      }
      return '5% Flat Deferral Rate';
    case 6:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '6% Auto-Escalate';
      }
      return '6% Flat Deferral Rate';
    case 7:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '7% Auto-Escalate';
      }
      return '7% Flat Deferral Rate';
    case 8:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return '8% Auto-Escalate';
      }
      return '8% Flat Deferral Rate';
    case 9:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        // API doesnt like 9% Auto-Escalating and 10% Auto-Escalating
        return false;
      }
      return '9% Flat Deferral Rate';
    case 10:
      if (
        autoEscalateAutoEnrolledDeferrals.value === 'yes' ||
        autoEscalateAutoEnrolledDeferrals.value === 'ESCALATING'
      ) {
        return false;
      }
      return '10% Flat Deferral Rate';
    default:
      return false;
  }
};

export const getFundList = (investmentLineup) => {
  switch (investmentLineup.value) {
    case 'fundLineup1':
      return 'Simply Fund Lineup 1';
    case 'fundLineup2':
      return 'Simply Fund Lineup 2';
    default:
      return 'Simply Fund Lineup 3';
  }
};

export const getReferrer = (formData, flag) => {
  const { planOriginator } = formData;
  const { hasFp, hasTpa, sponsorEmail } = formData.planDesign.questions;
  const advisorEmail = hasFp.value
    ? formData.planDesign.advisors[0].questions.advisorEmail.value
    : '';
  const tpaEmail = hasTpa.value
    ? formData.planDesign.tpas[0].questions.tpaEmail.value
    : '';

  let email;
  let type;
  switch (planOriginator.role) {
    // SMB > FP > TPA > SMB or
    // SMB > FP > SMB or
    // SMB > TPA > SMB
    case 'employer':
      email = sponsorEmail.value !== '' ? sponsorEmail.value : '';
      type = 'employer';
      break;
    // FP > TPA > FP > SMB or
    // FP > SMB
    case 'advisor':
      email = advisorEmail !== '' ? advisorEmail : '';
      type = 'advisor';
      break;
    // TPA > FP > TPA > SMB or
    // TPA > SMB
    case 'tpa':
      email = tpaEmail !== '' ? tpaEmail : '';
      type = 'tpa';
      break;
    default:
      email = '';
      type = '';
  }
  return flag === 'email' ? email : type;
};

export const getProfitMatchVesting = (
  hasTpa,
  discretionarySharing,
  vestingScheduleProfit,
  safeHarbor,
  vestingSchedule,
) => {
  if (hasTpa.value) {
    // return discretionarySharing.value === 'yes'
    //   ? vestingScheduleProfit.valueFormatted
    //   : '';
    return '';
  }
  return safeHarbor.value === 'nonSafeHarbor'
    ? vestingSchedule.valueFormatted
    : '';
};

export const setEligibilityAge = (ageReqEligibility) => {
  if (ageReqEligibility === 'age18' || ageReqEligibility === '18') return '18';
  if (ageReqEligibility === 'age21' || ageReqEligibility === '21') return '21';
  return 'None';
};

export const normalizeAdvisorRole = (brokerType) => {
  if (brokerType === 'brokerDealer') return 'RR';
  if (brokerType === 'iarRia') return 'IAR';
  return 'IA';
};

export const normalizeAdvisorFirmName = (
  brokerType,
  insuranceAgency,
  advisorFirm,
) => {
  if (brokerType === 'insuranceAgent') return insuranceAgency;
  return advisorFirm.value;
};
