export const nextStepPermissibleWithdrawals = (
  formData,
  isFlowPathA = true,
) => {
  const {
    autoEnrollment,
    autoEnrollmentPlan,
    b_autoEnrollment, // eslint-disable-line camelcase
    b_autoEnrollmentPlan, // eslint-disable-line camelcase
  } = formData.planDesign.questions;
  const aOrB = isFlowPathA ? 'a' : 'b';
  const enrollmentPlanValue = isFlowPathA
    ? autoEnrollmentPlan.value
    : b_autoEnrollmentPlan.value;

  if (isFlowPathA) {
    if (autoEnrollment.value === 'no') {
      return 'a_step_11_1';
    }
  } else if (b_autoEnrollment.value === 'no') {
    return 'b_step_11_1';
  }

  switch (enrollmentPlanValue) {
    case 'aca':
    case 'eaca':
      return `${aOrB}_step_11_aca_1`;
    case 'qaca':
      return `${aOrB}_step_11_qaca_1`;
    default:
      return `${aOrB}_step_11_1`;
  }
};

export const isPreviousStepPermissibleWithdrawals = (formData, isFlowPathA) => {
  const {
    permissibleWithdrawals,
    b_permissibleWithdrawals, // eslint-disable-line camelcase
    autoEnrollment,
    b_autoEnrollment, // eslint-disable-line camelcase
  } = formData.planDesign.questions;
  const aOrB = isFlowPathA ? 'a' : 'b';
  const permissibleSelection = isFlowPathA
    ? permissibleWithdrawals.value
    : b_permissibleWithdrawals.value;

  const autoEnrollmentElection = isFlowPathA
    ? autoEnrollment.value
    : b_autoEnrollment.value;

  if (autoEnrollmentElection === 'no')
    return isFlowPathA ? 'a_step_10_4' : 'b_step_10_4';

  return permissibleSelection === 'yes'
    ? `${aOrB}_step_11_permissible_2`
    : `${aOrB}_step_11_permissible_1`;
};

export const resetPermissibleWithdrawalQuestions = (
  permissibleQ,
  numPermissibleQ,
  updateQuestion,
  updateQuestionById,
  updateValue,
) => {
  if (updateValue === 'no') {
    updateQuestionById(permissibleQ.id, '', false, false, '');
    updateQuestionById(numPermissibleQ.id, '', false, false, '');
    updateQuestion({
      ...permissibleQ,
      required: false,
      dirty: false,
    });
    updateQuestion({
      ...numPermissibleQ,
      required: false,
      dirty: false,
    });
  } else {
    updateQuestion({
      ...permissibleQ,
      required: true,
      dirty: false,
      value: '',
      valueFormatted: '',
    });
    updateQuestion({
      ...numPermissibleQ,
      required: true,
      dirty: false,
      value: '',
      valueFormatted: '',
    });
  }
};
