import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  NextStepBtn,
  ReviewPlanBtn,
  checkValidityOnQuestionArray,
  Question,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import FormDataRedux, {
  isFormValid,
  selectAdvisorQuestions,
  selectTpaQuestions,
} from '../../../Redux/FormDataRedux';
import QuestionWrapper from '../../../common/FormWithSteps/QuestionWrapper';

export const EmployerPaidParticipantFees = ({
  role,
  active,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  googleData,
  isValid,
  tpaInfo,
  history,
  putApplicationPanelInfo,
  advisorInfo,
}) => {
  const { hasTpa, employerPaidParticipantFees } = questions;
  const toggleInfoPanel = () => {
    putApplicationPanelInfo({
      hasVisibility: true,
      heading: questions[questionId].heading,
      additionalInformation: questions[questionId].content,
    });
  };

  const { dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);
  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  const destinationPath = () => {
    if (role === 'psivet') {
      updateActiveStep(destination(), 'forward');
      return false;
    }
    if (hasTpa.value) {
      tpaInfo.push(employerPaidParticipantFees);
      if (checkValidityOnQuestionArray(tpaInfo) && formInReview && isValid) {
        history.push('/review-plan');
        return false;
      }
      updateActiveStep('shareForm', 'forward');
      return false;
    }
    updateActiveStep(destination(), 'forward');
    return true;
  };

  const showNextButton = () => {
    if (hasTpa.value) {
      if (checkValidityOnQuestionArray(tpaInfo) && formInReview && isValid)
        return false;
      return true;
    }
    return true;
  };

  const showReviewButton = () => {
    if (hasTpa.value) {
      return checkValidityOnQuestionArray(tpaInfo) && formInReview && isValid;
    }
    return checkValidityOnQuestionArray(advisorInfo) && isValid && formInReview;
  };

  const previousStepPath = (prevStep) => {
    if (role === 'psivet') {
      updateActiveStep(previousStep(), 'back');
      return false;
    }
    if (hasTpa.value) {
      if (role === 'employer') {
        updateActiveStep('employerQuestions', 'back');
        return false;
      }
      if (role === 'advisor') {
        updateActiveStep('isCurrentPrincipalPlan', 'back');
        return false;
      }
      updateActiveStep(prevStep, 'back');
      return false;
    }
    updateActiveStep(previousStep(), 'back');
    return false;
  };

  return (
    <div className="container-fluid h-100">
      <QuestionWrapper>
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Find out how the SECURE 2.0 Act could help.
            </button>
          </p>
        )}
        <p
          className={classNames({
            fws__control: !formInReview,
            'fws__control-group': formInReview,
          })}
        >
          {showNextButton() && (
            <NextStepBtn
              text="Next step"
              disabled={
                questions[questionId].required ? !(dirty && !hasError) : false
              }
              onClick={() => {
                destinationPath(destination());
              }}
            />
          )}
          {showReviewButton() && (
            <ReviewPlanBtn
              disabled={!isValid}
              goToReviewPlan={goToReviewPlan}
            />
          )}
        </p>
      </QuestionWrapper>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          previousStepPath(previousStep);
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

EmployerPaidParticipantFees.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  isValid: PropTypes.bool,
  tpaInfo: PropTypes.array.isRequired,
  history: PropTypes.object,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  advisorInfo: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
  tpaInfo: selectTpaQuestions(store),
  advisorInfo: selectAdvisorQuestions(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployerPaidParticipantFees);
