import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Question,
  StepControls,
  isEligible,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import FormDataRedux, { isFormValid } from '../../../Redux/FormDataRedux';
import QuestionWrapper from '../../../common/FormWithSteps/QuestionWrapper';

export const UnionEmployees = ({
  role,
  active,
  googleData,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  putApplicationPanelInfo,
  isValid,
}) => {
  const { hasUnionEmployees, numberOfUnionEmployees, numberOfEmployees } =
    questions;
  const toggleInfoPanel = () => {
    putApplicationPanelInfo({
      hasVisibility: true,
      heading: numberOfUnionEmployees.heading,
      additionalInformation: numberOfUnionEmployees.content,
    });
  };

  const { value, dirty, hasError } = questions[questionId];

  const disabledBasedOnRole = () => {
    if (!hasUnionEmployees.dirty) return true;

    if (hasUnionEmployees.value === 'yes') {
      if (!numberOfUnionEmployees.dirty) return true;

      const unionEmployeeCount = parseInt(numberOfUnionEmployees.value, 10);
      const totalEmployeeCount = parseInt(numberOfEmployees.value, 10);

      if (
        !numberOfUnionEmployees.value ||
        unionEmployeeCount >= totalEmployeeCount ||
        !isEligible(unionEmployeeCount)
      ) {
        return true;
      }
    }

    return false;
  };

  const validate = !!(dirty && hasError);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );
    if (id === 'hasUnionEmployees' && updateValue === 'no') {
      updateQuestionById(numberOfUnionEmployees.id, '', false, true, '');
    }
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <QuestionWrapper>
        <Question
          role={role}
          type={hasUnionEmployees.type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={hasUnionEmployees}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!hasUnionEmployees.heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="fs-p lh-125 text-danger text-center">
            Question is required
          </p>
        )}
        {hasUnionEmployees.value === 'yes' && (
          <Question
            role={role}
            type={numberOfUnionEmployees.type}
            labelClass="fs-form-q fw-bold text-center pt-3 pb-5 px-3 d-block"
            inputClass="text-question"
            thousandSeparator
            question={numberOfUnionEmployees}
            handleQuestionChange={handleQuestionChange}
            hasInfo={!!numberOfUnionEmployees.heading}
            toggleInfoPanel={toggleInfoPanel}
          />
        )}
        {numberOfUnionEmployees.heading && (
          <p className="text-center mb-0">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={isValid && formInReview}
          goToReviewPlan={goToReviewPlan}
          disableReviewBtn={!isEligible(value) && !isValid}
          disabledNextBtn={disabledBasedOnRole()}
          clickNextBtn={() => {
            destination(isEligible(value), updateActiveStep);
          }}
        />
      </QuestionWrapper>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

UnionEmployees.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
  googleData: PropTypes.object,
  questionId: PropTypes.string,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnionEmployees);
