export const numberOfEmployees = {
  id: 7,
  stepName: 'step_1',
  type: 'number_field',
  name: 'numberOfEmployees',
  hasInfo: true,
  infoLabel: '',
  user: {
    employer: {
      label:
        'How many people, including the owner, are currently employed by the business?',
    },
    advisor: {
      label:
        'How many people, including the owner, are currently employed by the business?',
    },
    tpa: {
      label:
        'How many people, including the owner, are currently employed by the business?',
    },
  },
  heading: 'Include all full-time and part-time employees.',
  content: `Union employees, independent contractors, and nonresident aliens aren’t eligible for Simply Retirement by Principal<small><sup>®</sup></small>.`,
  placeholder: 'Number of employees',
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  valueFormatted: '',
  hasConditional: true,
  validateRule: 'LARGER_EMPLOYEE_RULE',
  hasError: false,
  sideCardTitle: 'Number of eligible employees',
  reviewScreenTitle: 'Total number of employees',
  description:
    'This is the number of full-time and part-time employees age 21 or older (including the business owner) in the business.',
};
