import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const QuestionWrapper = ({ children }) => (
  <div className="row h-100">
    <div className={classNames('col-md-10 col-xl-8 d-flex flex-column m-auto')}>
      {children}
    </div>
  </div>
);

QuestionWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default QuestionWrapper;
