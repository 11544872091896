import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CostEstimatorCard from '../../../common/FormWithSteps/PlanOutLine/CostEstimatorCard';
import { numberToCurrency } from '../../../common/FormWithSteps/Utilities';
import { numberOfEligibleEmployees } from '../../../Services/numberOfEligibileEmployeesHelper';

const CostEstimator = ({ questions, setNotificationIndicator }) => {
  const {
    employerPaidParticipantFees,
    averageSalary,
    employerContributionMatch,
    advisorCompensation,
    advisorType,
    advisorFee,
    advisorPoints,
    planUsingSafeHarbor: safeHarbor,
    autoDeferralPercentageStartEmployees,
    whichSafeHarbor,
    tpaSetupFee,
    annualTpaFee,
    autoEnrollmentPlan,
  } = questions;

  const advisorFeeValue = () => {
    if (
      advisorCompensation.valueFormatted ===
        'Deducted from participant accounts' &&
      advisorType.valueFormatted === 'Flat Fee'
    ) {
      return `$${parseFloat(
        advisorFee.value / (numberOfEligibleEmployees(questions) * 12),
      ).toFixed(2)}`;
    }
    return '';
  };
  const buisnessOwnerMatchValue = () => {
    if (safeHarbor.value === 'no') {
      return 0;
    }
    if (whichSafeHarbor.value.id === 2) {
      if (
        autoEnrollmentPlan.value === 'aca' ||
        autoEnrollmentPlan.value === 'eaca'
      ) {
        switch (autoDeferralPercentageStartEmployees.value) {
          case 3:
            return 3;
          case 4:
            return 3.5;
          default:
            return 4;
        }
      } else {
        switch (autoDeferralPercentageStartEmployees.value) {
          case 3:
            return 2;
          case 4:
            return 2.5;
          case 5:
            return 3;
          case 6:
            return 3.5;
          default:
            return 4;
        }
      }
    } else {
      return autoDeferralPercentageStartEmployees.value;
    }
  };

  const ongoingRecordFee = (annualTpaFee.value / 12).toFixed(2);
  const businessOwnerCostCardsTitle = 'Estimated Business Owner Plan Costs';
  const businessOwnerCostCards = [
    {
      id: 1,
      title: 'Ongoing recordkeeping fee',
      value: '$145',
      period: 'per month',
      hasInfo: false,
    },
    {
      id: 2,
      title: 'Ongoing participant fee',
      value:
        employerPaidParticipantFees.value === 'true'
          ? `$${parseInt(numberOfEligibleEmployees(questions), 10) * 6}`
          : '',
      period: 'per month',
      hasInfo: true,
      info: `<p class="fs-sm mb-0"><strong>&ensp;&ensp;&ensp;${numberOfEligibleEmployees(
        questions,
      )}</strong> employees</p><p class="fs-sm mb-0">x&ensp;&ensp;<strong>$6 per-participant</strong> fee</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&ensp;&ensp;<strong>$${
        parseInt(numberOfEligibleEmployees(questions), 10) * 6
      } per month
      </strong></p><p class="fs-sm">As long as you maintain the same number of employees, this amount will also remain the same, year after year.</p>`,
    },
    {
      id: 3,
      header: 'Third party administrators',
      title: 'One-time setup fee',
      value: `$${tpaSetupFee.value}`,
      period: '',
      hasInfo: false,
    },
    {
      id: 4,
      title: 'Ongoing recordkeeping fee',
      value: `${numberToCurrency(ongoingRecordFee)}`,
      period: 'per month',
      hasInfo: false,
    },
  ];
  const businessOwnerContributionCardTitle =
    'Estimated Business Owner Contributions';
  const businessOwnerContributionCard = [
    {
      id: 1,
      title: 'Ongoing matching contributions',
      value: `${numberToCurrency(
        parseInt(
          (averageSalary.value *
            numberOfEligibleEmployees(questions) *
            (buisnessOwnerMatchValue() / 100)) /
            12,
          10,
        ),
        2,
      )}`,
      period: 'per month',
      hasInfo: true,
      info: `<p class="fs-sm mb-0">&#160;&#160;&#160;<strong>${numberToCurrency(
        parseInt(averageSalary.value, 10),
      )}</strong> per year avg. salary</p><p class="fs-sm mb-0">x&#160;&#160;<strong>${buisnessOwnerMatchValue()}%</strong> matching contribution</p><p class="fs-sm mb-0">x&#160;&#160;<strong>${parseInt(
        numberOfEligibleEmployees(questions),
        10,
      )}</strong> employees</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>12</strong> months</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&#160;&#160;<strong>${numberToCurrency(
        parseInt(
          (averageSalary.value *
            numberOfEligibleEmployees(questions) *
            (buisnessOwnerMatchValue() / 100)) /
            12,
          10,
        ),
        2,
      )}
      </strong></p>
      <p class="fs-sm">Matching contribution is estimated based on the average annual salary and number of employees you entered.</p>`,
    },
  ];
  const employeeCardTitle = 'Estimated Employee Costs';
  const employeeCostCards = [
    {
      id: 1,
      title: 'Ongoing per-participant fee',
      value:
        employerPaidParticipantFees.valueFormatted === 'Employees' ? '$6' : '',
      period: 'per month',
      hasInfo: false,
    },
    {
      id: 2,
      title: 'Financial Professional fee',
      value:
        advisorCompensation.valueFormatted ===
          'Deducted from participant accounts' &&
        advisorType.valueFormatted === 'Basis Points'
          ? `${parseInt(advisorPoints.valueFormatted, 10) / 100}%`
          : '',
      period: 'per year',
      hasInfo: true,
      info: `<p class="fs-sm mb-0">This fee will be calculated based on assets in the plan. With a financial professional fee of <strong>.25%</strong>, participating employees will pay <strong>$2.50 for every $1,000</strong> they have in the plan annually (not to exceed $5,000 per year across all participants in plan). This amount will be deducted quarterly from participants’ plan assets.</p>`,
    },
    {
      id: 3,
      title: 'Financial Professional fee',
      value: advisorFeeValue(),
      period: 'per year',
      hasInfo: true,
      info: `<p class="fs-sm mb-0"><strong>&ensp;&ensp;${
        advisorFee.value
      }</strong> per year</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>12</strong> months</p><p class="fs-sm mb-0">&#247;&#160;&#160;<strong>${numberOfEligibleEmployees(
        questions,
      )}</strong> employees</p><p class="fs-sm cost-estimator-info-border mt-2 pt-2">=&ensp;&ensp;<strong>${advisorFeeValue()} per month
      </strong></p><p class="fs-sm">As long as you maintain the same number of employees, this amount will also remain the same, year after year.</p>`,
    },
  ];

  useEffect(() => {
    if (
      employerPaidParticipantFees.valueFormatted === 'Business owner' ||
      employerContributionMatch.valueFormatted === 'Yes' ||
      employerPaidParticipantFees.valueFormatted === 'Employees' ||
      advisorCompensation.valueFormatted ===
        'Deducted from participant accounts'
    ) {
      setNotificationIndicator(true);
    }
  }, [
    employerPaidParticipantFees.valueFormatted,
    advisorCompensation.valueFormatted,
    employerContributionMatch.valueFormatted,
    employerPaidParticipantFees.valueFormatted,
  ]);

  return (
    <>
      <CostEstimatorCard
        title={businessOwnerCostCardsTitle}
        cards={businessOwnerCostCards}
      />
      {safeHarbor.value === 'yes' && (
        <CostEstimatorCard
          title={businessOwnerContributionCardTitle}
          cards={businessOwnerContributionCard}
        />
      )}
      {(employerPaidParticipantFees.valueFormatted === 'Employees' ||
        advisorCompensation.valueFormatted ===
          'Deducted from participant accounts') && (
        <CostEstimatorCard
          title={employeeCardTitle}
          cards={employeeCostCards}
        />
      )}
    </>
  );
};

CostEstimator.propTypes = {
  questions: PropTypes.object,
  setNotificationIndicator: PropTypes.func,
};

export default CostEstimator;
