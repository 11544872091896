import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux, {
  selectAutoEnrolledPlan,
  selectIsAutoEnrolled,
} from '../../../../Redux/FormDataRedux';
import AutoEnrollmentQuestions from './AutoEnrollmentQuestion';
import {
  pushGoogleDataLayer,
  checkForErrors,
  multiQuestionNextDisabled,
} from '../../../../common/FormWithSteps/Utilities';
import {
  StepControls,
  PrevStepBtn,
  checkValidityOnQuestionArray,
} from '../../../../common/FormWithSteps';
import { resetPermissibleWithdrawalQuestions } from '../stepFlowUtilities';

const AutoEnrollment = ({
  active,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  updateQuestion,
  googleData,
  autoEnrolledPlan,
  isAutoEnrolled,
  destination,
  resetUnusedEnrollmentOptions,
  isValid,
  ...stepProps
}) => {
  const primaryQuestion = questions[stepProps.questionId];
  const secondaryQuestion = questions[stepProps.subQuestionIds[0]];
  const permissibleQ =
    stepProps.questionId === 'autoEnrollment'
      ? questions.permissibleWithdrawals
      : questions.b_permissibleWithdrawals;
  const numPermissibleQ =
    stepProps.questionId === 'autoEnrollment'
      ? questions.numberOfPermissibleDays
      : questions.b_numberOfPermissibleDays;

  const [validateQuestions, setValidateQuestions] = useState([]);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestion({
      name: id,
      value: updateValue,
      valueFormatted,
      hasError: checkForErrors(id, updateValue),
      dirty: true,
    });
    if (id === primaryQuestion.name)
      updateQuestion({
        name: secondaryQuestion.name,
        value: updateValue === 'no' ? '' : secondaryQuestion.value,
        valueFormatted:
          updateValue === 'no' ? '' : secondaryQuestion.valueFormatted,
        dirty: true,
        required: updateValue === 'yes',
      });
    resetPermissibleWithdrawalQuestions(
      permissibleQ,
      numPermissibleQ,
      updateQuestion,
      updateQuestionById,
      updateValue,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedEnrollmentOptions(primaryQuestion, secondaryQuestion);
    if (primaryQuestion.value === 'yes') {
      setValidateQuestions([primaryQuestion, secondaryQuestion]);
    } else {
      setValidateQuestions([primaryQuestion]);
    }
  }, [primaryQuestion, secondaryQuestion]);

  return (
    <div className="container-fluid--sitewidth h-100">
      <AutoEnrollmentQuestions
        primaryQuestion={primaryQuestion}
        secondaryQuestion={secondaryQuestion}
        handleQuestionChange={handleQuestionChange}
      />
      <div className="fws__control">
        <StepControls
          formInReview={stepProps.formInReview}
          disableReviewBtn={!isValid}
          disabledNextBtn={
            !checkValidityOnQuestionArray(validateQuestions) ||
            multiQuestionNextDisabled(primaryQuestion, secondaryQuestion)
          }
          goToReviewPlan={stepProps.goToReviewPlan}
          clickNextBtn={() => {
            updateActiveStep(destination(isAutoEnrolled), 'forward');
          }}
        />
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

AutoEnrollment.propTypes = {
  active: PropTypes.bool,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  updateQuestion: PropTypes.func.isRequired,
  googleData: PropTypes.object,
  autoEnrolledPlan: PropTypes.string.isRequired,
  isAutoEnrolled: PropTypes.string.isRequired,
  destination: PropTypes.func.isRequired,
  resetUnusedEnrollmentOptions: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  autoEnrolledPlan: selectAutoEnrolledPlan(store),
  isAutoEnrolled: selectIsAutoEnrolled(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetUnusedEnrollmentOptions: (enrollment, enrollmentPlan) => {
    dispatch(
      FormDataRedux.resetUnusedEnrollmentOptions(enrollment, enrollmentPlan),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoEnrollment);
