import API from '../../api';
import {
  checkRegisterTpaResponseStatus,
  getAdvisorBrokerDealer,
  getInvestmentLineup,
  handlePlanNumber,
  paradigmPaymentMethod,
  setParadigmAdvisors,
  setVestingSchedule,
} from './ParadigmUtilities';
import {
  paradigmPackagerUnbundled,
  paradigmPlanAddSourcesUnbundled,
  setTpaInfoParadigmRegistration,
} from './unbundledPlanUtilities';
import { paradigmPackagerBundled } from './BundledPlanUtilities';
import { TRANSFER_PLAN_VERIFICATION_CODE } from '../../../Redux/FormDataRedux/InitialState/PlanDesignInitialState';
import { brokerTypeForParadigm } from './brokerTypeForParadigm';
import { permissibleWithdrawalsHelper } from './ParadigmUtilities/permissibleWithdrawalsHelper';

const date = () => new Date();
const api = API.create();

export const planHelper = async (
  planDesign,
  advisorCvStatus,
  tpaParadigmPackager = [], // eslint-disable-line default-param-last
  authenticityToken,
  userRole = 'employer',
  proposal = [],
) => {
  const {
    companyName,
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    companyPhone,
    employerStateCode,
    employerPaidParticipantFees,
    planEffectiveDate: defaultPlanEffectiveDate,
    advisorFirmBroker,
    employerIdentificationNumber,
    investmentLineup,
    hasTpa,
    hasFp,
    isCurrentPrincipalPlan,
    transferPlanInputVerificationCode,
  } = planDesign.questions;
  const { advisors, tpas } = planDesign;
  const {
    planEffectiveDate: tpaPlanEffectiveDate = { value: '', valueFormatted: '' },
    planName = { value: '', valueFormatted: '' },
    planNumber,
  } = tpaParadigmPackager;
  const planEffectiveDate = hasTpa.value
    ? tpaPlanEffectiveDate
    : defaultPlanEffectiveDate;
  const vestingSchedule = setVestingSchedule(planDesign, tpaParadigmPackager);
  const isTransferPlan =
    isCurrentPrincipalPlan.value === 'yes' &&
    transferPlanInputVerificationCode.value === TRANSFER_PLAN_VERIFICATION_CODE;

  let paradigmPlan = {
    advisorFirmBroker: advisorFirmBroker?.value.broker_dealer_name || null,
    companyName: companyName.value,
    companyPhone: companyPhone.valueFormatted,
    employerPaidParticipantFees: employerPaidParticipantFees.value === 'true',
    irsPlanNumber: handlePlanNumber(planNumber),
    isConversion: isTransferPlan,
    lastRestatementDate: isTransferPlan
      ? getPlanEffectiveDate(planEffectiveDate.value)
      : null,
    planEffectiveDate: getPlanEffectiveDate(planEffectiveDate.value),
    planName: planName?.value || '',
    portfolioCode: investmentLineup.dirty
      ? getInvestmentLineup(investmentLineup)
      : 'principal_std',
    numberOfEmployees: parseInt(
      planDesign.questions.numberOfEmployees.value,
      10,
    ),
    sponsorFirstName: sponsorFirstName.value,
    sponsorLastName: sponsorLastName.value,
    sponsorEmail: sponsorEmail.value,
    stateCode: employerStateCode.value,
    trustees: [
      //   {
      //     firstName: null,
      //     lastName: null,
      //     email: null,
      //   },
    ],
    proposal,
  };

  const unbundledPlanData = hasTpa.value
    ? await paradigmPackagerUnbundled(planDesign, tpaParadigmPackager, tpas)
    : {};

  if (
    hasTpa.value &&
    tpas[1] !== undefined &&
    tpas[1].questions.tpaEmail.value !== ''
  ) {
    const response = await api.registerTpa(
      await setTpaInfoParadigmRegistration(planDesign, tpas, authenticityToken),
    );
    if (checkRegisterTpaResponseStatus(response.status)) {
      const {
        feeCollectionSource,
        fixedAmount,
        bpsAmount,
        tpaDistributionFee,
        tpaHardshipFee,
        tpaLoanMaintenanceFee,
        tpaLoanOriginationFee,
        tpaLoanReamortizationFee,
        tpaRmdFee,
        tpaQdroFee,
      } = planDesign.questions;
      unbundledPlanData.tpa = {
        tpaEin: employerIdentificationNumber.value,
        disbursedByUbiquity:
          getInvestmentLineup(investmentLineup) === 'onedigital',
        users: [
          {
            email: tpas[1].questions.tpaEmail.value,
            roles: ['IMPLEMENTATIONS'],
          },
        ],
        feeCollectionSource: feeCollectionSource.value,
        fixedAmount: fixedAmount.value === '' ? null : fixedAmount.value,
        bpsAmount: bpsAmount.value === '' ? null : bpsAmount.value,
        processingFees: [
          {
            type: 'TPA_DISTRIBUTION_FEE',
            amount: tpaDistributionFee.value || 0,
          },
          {
            type: 'TPA_HARDSHIP_FEE',
            amount: tpaHardshipFee.value || 0,
          },
          {
            type: 'TPA_LOAN_MAINTENANCE_FEE',
            amount: tpaLoanMaintenanceFee.value || 0,
          },
          {
            type: 'TPA_LOAN_ORIGINATION_FEE',
            amount: tpaLoanOriginationFee.value || 0,
          },
          {
            type: 'TPA_LOAN_REAMORTIZATION_FEE',
            amount: tpaLoanReamortizationFee.value || 0,
          },
          {
            type: 'TPA_RMD_FEE',
            amount: tpaRmdFee.value || 0,
          },
          {
            type: 'TPA_QDRO_FEE',
            amount: tpaQdroFee.value || 0,
          },
        ],
      };
    }

    unbundledPlanData.addSources = await paradigmPlanAddSourcesUnbundled(
      planDesign,
      tpaParadigmPackager,
      vestingSchedule,
    );

    paradigmPlan = {
      ...paradigmPlan,
      ...unbundledPlanData,
    };
  } else {
    const bundledPlanData = await paradigmPackagerBundled(planDesign, userRole);

    paradigmPlan = {
      ...paradigmPlan,
      ...bundledPlanData,
    };
  }

  if (
    hasFp.value &&
    advisors !== undefined &&
    advisors[0].questions.advisorEmail.value !== ''
  ) {
    paradigmPlan.advisors = setParadigmAdvisors(
      advisors,
      planDesign,
      advisorCvStatus,
      userRole,
    );
  }

  const permissibleWithdrawals = permissibleWithdrawalsHelper(
    planDesign.questions,
    tpaParadigmPackager,
  );

  return {
    ...paradigmPlan,
    ...(permissibleWithdrawals && permissibleWithdrawals),
  };
};

export const collectSavedPlanData = (formData) => {
  const { role, hashId, planDesign } = formData;
  const {
    sponsorFirstName,
    sponsorLastName,
    sponsorEmail,
    firmNameFreeForm,
    companyName,
    tpaFirmName,
    tpaFirmNameUnlisted,
    employerIdentificationNumber,
    hasFp,
    investmentLineup,
  } = planDesign.questions;
  const { advisors, tpas } = formData.planDesign;
  const { advisorFirstName, advisorLastName, advisorEmail } =
    advisors[0].questions;

  const { tpaEmail } = tpas[0].questions;

  const getTPA = (index, key) =>
    formData.planDesign.tpas[index].questions[key] || null;

  const packageTPAs = () => {
    if (!getTPA(0, 'tpaEmail').dirty) return null;
    return [
      {
        firstName: getTPA(1, 'tpaFirstName').value,
        lastName: getTPA(1, 'tpaLastName').value,
        email: getTPA(1, 'tpaEmail').value,
        phone: getTPA(1, 'tpaPhone')?.valueFormatted,
        firmName:
          tpaFirmName.value.value === 'myFirmIsntListed'
            ? tpaFirmNameUnlisted.value
            : tpaFirmName.value.label,
        ein: employerIdentificationNumber.value,
      },
    ];
  };
  const crd = advisors[0].advisorCredentialVerification.crd.value;
  const { brokerType, insuranceAgentCompType } =
    advisors[0].advisorCredentialVerification;

  const compensation = formData.planDesign.questions.advisorCompensation.value;

  const paradigmAdvisorData = [
    {
      firmName: firmNameFreeForm.value,
      address1: '',
      address2: '',
      city: '',
      state:
        investmentLineup.value !== 'fundLineupOneDigital'
          ? advisors[0].questions.advisorStateCode.value
          : 'GA',
      zip:
        investmentLineup.value !== 'fundLineupOneDigital'
          ? advisors[0].questions.advisorZipCode.value
          : '30339',
      phone:
        investmentLineup.value !== 'fundLineupOneDigital'
          ? advisors[0].questions.advisorPhone?.valueFormatted
          : '888-470-2121',
      fax: '',
      brokerDealer: getAdvisorBrokerDealer(
        brokerType,
        advisors[0],
        compensation,
        role,
      ),
    },
  ];

  const firmType = brokerTypeForParadigm(
    advisors[0].advisorCredentialVerification.brokerType,
  );

  switch (
    paradigmPaymentMethod(
      compensation,
      brokerType,
      insuranceAgentCompType,
      role,
      investmentLineup,
    )
  ) {
    case 'ia-direct':
      paradigmAdvisorData[0].paymentMethod = {
        deliveryMethod: 'DIRECT',
        firmType: null,
      };
      break;
    case 'ia-indirect':
      paradigmAdvisorData[0].paymentMethod = {
        deliveryMethod: 'FIRM',
        npn: advisors[0].advisorCredentialVerification.insuranceAgentNpn,
        firmType,
      };
      break;
    case 'onedigital':
      paradigmAdvisorData[0].paymentMethod = {
        deliveryMethod: 'FIRM',
        crd: '106766',
        firmType,
      };
      break;
    case 'psivet':
    case 'indirect':
      paradigmAdvisorData[0].paymentMethod = {
        deliveryMethod: 'FIRM',
        crd,
        firmType,
      };
      break;
    default:
      paradigmAdvisorData[0].paymentMethod = {
        deliveryMethod: 'FIRM',
        crd,
        firmType,
      };
      break;
  }
  paradigmAdvisorData[0].advisorUsers = [];
  advisors.map((advisor, index) => {
    paradigmAdvisorData[0].advisorUsers[index] = {
      id: index,
      firstName: advisor.questions.advisorFirstName.value,
      lastName: advisor.questions.advisorLastName.value,
      email: advisor.questions.advisorEmail.value,
      ...(index === 0 && { proposals: [formData.planURL] }),
      npn:
        advisor.questions.advisorNpn.value ||
        advisor.questions.advisorEmail.value,
      floridaLicenseNumber:
        advisor.questions.floridaLicenseNumber.value || undefined,
      ...(advisor.questions.advisorRepNumber && {
        repNumber: advisor.questions.advisorRepNumber.value,
      }),
      ...(investmentLineup.value === 'fundLineupOneDigital'
        ? { crd: advisor.advisorCredentialVerification.crd.value }
        : {}),
    };
    return paradigmAdvisorData;
  });

  const setSavePlanCreatorName = () => {
    switch (role) {
      case 'advisor':
        return {
          name: `${advisorFirstName.value} ${advisorLastName.value}`,
          email: advisorEmail.value,
          role: 'advisor',
        };
      case 'tpa':
        return {
          name: `${getTPA(0, 'tpaFirstName').value} ${
            getTPA(0, 'tpaLastName').value
          }`,
          email: tpaEmail.value,
          role: 'tpa',
        };
      default:
        return {
          name: `${sponsorFirstName.value} ${sponsorLastName.value}`,
          email: sponsorEmail.value,
          role: 'employer',
        };
    }
  };
  const savePlanData = {
    company_name: companyName.value,
    state: {
      formData: {
        ...formData,
        createEmailSent: true,
        planOriginator:
          formData.planOriginator === ''
            ? setSavePlanCreatorName()
            : formData.planOriginator,
      },
    },
    name:
      formData.planOwner === ''
        ? setSavePlanCreatorName().name
        : formData.planOwner,

    paradigm_advisors: hasFp.value ? paradigmAdvisorData : null,
    paradigm_tpas: packageTPAs(tpas),
  };

  if (sponsorEmail.value !== undefined && sponsorEmail.value !== '') {
    savePlanData.email = sponsorEmail.value;
  }
  if (hashId !== '') {
    savePlanData.hashId = hashId;
  }
  return savePlanData;
};

export const requireConfirmation = (
  safeHarbor,
  planEffectiveDate = new Date(),
) => {
  const startDate = new Date(date().getFullYear(), 10, 1);
  const endDate = new Date(date().getFullYear(), 11, 31);
  return (
    new Date(planEffectiveDate) >= startDate &&
    new Date(planEffectiveDate) <= endDate &&
    safeHarbor.value === 'safeHarbor'
  );
};

export const selectedSafeHarbor = (questions) => {
  const safeHarbor = 'safeHarbor';
  return questions[safeHarbor].value === safeHarbor;
};

export const getPlanEffectiveDate = (effectiveDate) => {
  const [month, day, year] = date().toLocaleDateString('en-us').split('/');
  if (effectiveDate === getDate(year, month, day)) {
    return getDate(year, month, day);
  }
  return effectiveDate;
};

export const getDate = (year, month, day) =>
  `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

export const getDatePretty = (year, month, day) =>
  `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year}`;
