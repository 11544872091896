import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormDataRedux from '../../../Redux/FormDataRedux';
import {
  Question,
  StepControls,
  PrevStepBtn,
} from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';

export const NumberOfPermissibleDays = ({
  role,
  active,
  googleData,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  putApplicationPanelInfo,
  isValid,
}) => {
  const { value, dirty, hasError } = questions[questionId];

  const isValidNumberOfDays = (val) =>
    parseInt(val, 10) >= 30 && parseInt(val, 10) <= 90;

  const disabledBasedOnRole = () => {
    if (role === 'advisor' || role === 'psivet' || role === 'tpa') {
      if (dirty && !hasError) return !isValidNumberOfDays(value);
    }
    return !(dirty && !hasError);
  };
  const validate = !!(dirty && hasError);

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '' || !isValidNumberOfDays(updateValue),
      true,
      `${valueFormatted} days`,
    );
  };

  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  return (
    <div className="container-fluid h-100">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <Question
            role={role}
            type={questions[questionId].type}
            labelClass="fs-form-q fw-bold text-center pt-3 pb-5 px-3 d-block"
            inputClass={
              questions[questionId].type === 'number_field'
                ? 'text-question'
                : ''
            }
            thousandSeparator
            question={questions[questionId]}
            handleQuestionChange={handleQuestionChange}
          >
            {validate && (
              <p className="text-center">
                <small className="fs-p lh-125 text-danger text-center">
                  Question is required
                </small>
              </p>
            )}
          </Question>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="form-group fws__control">
            <button
              type="button"
              className="srp-button__link"
              onClick={() =>
                putApplicationPanelInfo({
                  hasVisibility: true,
                  heading: 'Need clarification?',
                  additionalInformation: questions[questionId].description,
                })
              }
            >
              Need clarification?
            </button>

            <StepControls
              formInReview={formInReview}
              goToReviewPlan={goToReviewPlan}
              disableReviewBtn={!isValid}
              disabledNextBtn={disabledBasedOnRole()}
              clickNextBtn={() => {
                updateActiveStep(destination(value), 'forward');
              }}
            />
          </div>
        </div>
      </div>
      <PrevStepBtn
        active={active}
        goTo={previousStep}
        updateActiveStep={updateActiveStep}
      />
    </div>
  );
};

NumberOfPermissibleDays.propTypes = {
  active: PropTypes.bool,
  role: PropTypes.string,
  googleData: PropTypes.object,
  questionId: PropTypes.string,
  destination: PropTypes.func,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  isValid: PropTypes.bool,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(null, mapDispatchToProps)(NumberOfPermissibleDays);
