export const hardshipWithdrawals = {
  id: 'q13',
  type: 'radio_condensed',
  name: 'hardshipWithdrawals',
  paradigmAlias: 'hardshipWithdrawals',
  user: {},
  stepName: 'step_17',
  label: 'Will the plan allow for hardship withdrawals?',
  infoLabel:
    'Your plan will automatically allow participants to take a loan from their account.',
  heading: 'What are hardship withdrawals?',
  content:
    'This provision allows participants to withdraw eligible amounts from their 401(k) account to meet the needs of a financial hardship. The IRS sets certain guidelines for circumstances that may qualify as a hardship withdrawal, such as out-of-pocket medical expenses, down payment or repairs on a primary home, college tuition and related educational expenses, the threat of mortgage foreclosure or eviction, or funeral and burial expenses, and more. However, the plan terms ultimately set the specific criteria of a hardship withdrawals.</br></br> Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59&frac12;. Additional requirements may apply based on the provisions of the plan.</br></br>Participants can request to take a loan from their 401(k) plan balance and select a loan repayment schedule that best suits them. Only one loan may be outstanding at a time.',
  options: [
    {
      id: 1,
      user: {},
      label: 'Yes',
      value: 'yes',
      valueFormatted: 'Yes',
      checked: false,
      customStyle: 'radio_confirm',
    },
    {
      id: 2,
      user: {},
      label: 'No',
      value: 'no',
      valueFormatted: 'No',
      checked: false,
      customStyle: 'radio_cancel',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  value: '',
  hasConditional: false,
  hasError: false,
  sideCardTitle: 'Hardship withdrawals',
  reviewScreenTitle: 'Hardship withdrawals',
  optionDescription: '',
  description:
    'This provision allows participants to withdraw from their 401(k) account to meet the needs of a real financial emergency based on specific criteria as specified in the plan. Participants must pay taxes on the amount they withdraw, plus a 10% penalty if they are under age 59½. Additional requirements may apply based on the provisions of the plan.',
};
