import API from '../../api';
const api = API.create();

export const restrictedStateCvFail = async (
  planDesign,
  advisor,
  hashId,
  authenticityToken,
) => {
  const {
    sponsorFirstName,
    sponsorLastName,
    employerStateCode,
    companyName,
    sponsorEmail,
    companyPhone,
  } = planDesign.questions;
  try {
    await api.restrictedStateCvFail({
      fail_data: {
        clientName: `${sponsorFirstName.value} ${sponsorLastName.value}`,
        company: companyName.value,
        accountState: employerStateCode.value,
        clientEmail: sponsorEmail.value,
        clientPhone: companyPhone.valueFormatted,
        recipient: advisor.questions.advisorEmail.value,
        hashId,
      },
      authenticity_token: authenticityToken,
    });
  } catch (error) {
    // fail silently
    // console.log(error);
  }
};
