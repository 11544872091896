import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// pin package @v9 https://github.com/react-component/slider/issues/736#issuecomment-1563991591
import Slider from 'rc-slider';
import Label from '../Label';
import { numberToCurrency } from '../../Utilities';

const { Handle } = Slider;

const handleCurrency = ({ value, dragging, index, ...restProps }) => (
  <Handle value={value} {...restProps}>
    <div
      className="range-slider__tooltip"
      style={{
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <h3 className="fs-h3 range-slider__tooltip-text fw-bold">
        {numberToCurrency(parseInt(value, 10))}
      </h3>
    </div>
  </Handle>
);

const handlePercentage = ({ value, dragging, index, ...restProps }) => (
  <Handle value={value} {...restProps}>
    <div
      className="range-slider__tooltip"
      style={{
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <h3 className="fs-h3 fw-bold range-slider__tooltip-text">{value}%</h3>
    </div>
  </Handle>
);

const markTooltip = (value, tooltipNumber, tooltipInfo) => (
  <div className={value === tooltipNumber ? 'd-block' : 'd-none'}>
    <div className="rc-slider-tooltip__head"></div>
    <div className="rc-slider-tooltip__body">
      <p className="rc-slider-tooltip__description">{tooltipInfo}</p>
    </div>
  </div>
);

const renderMarkTooltip = (value, tooltipNumber, tooltipInfo) => {
  if (tooltipNumber) {
    return {
      [tooltipNumber]: markTooltip(value, tooltipNumber, tooltipInfo),
    };
  }
  return {};
};

const RangeSlider = ({
  name,
  label,
  labelClass,
  hasError,
  validationErrors,
  min,
  max,
  step,
  value,
  defaultValue,
  thousandSeperator,
  tooltipNumber,
  tooltipInfo,
  handleQuestionChange,
  infoLabel,
  invert = false,
  format,
  required,
  uniqueId,
}) => {
  const handleInputChange = (sliderValue) => {
    let sliderValueFormatted;
    if (thousandSeperator) {
      sliderValueFormatted = numberToCurrency(parseInt(sliderValue, 10));
    } else {
      sliderValueFormatted = `${sliderValue}%`;
    }
    handleQuestionChange(name, sliderValue, sliderValueFormatted);
  };

  const [sliderValue, setSliderValue] = useState(value);
  return (
    <div className={classNames('mb-3 form-group', { 'has-error': hasError })}>
      <Label
        name={uniqueId || name}
        className={labelClass}
        text={label}
        required={required}
      />

      {infoLabel && (
        <div
          className="fs-p text-center lh-125 pb-5"
          dangerouslySetInnerHTML={{ __html: infoLabel }}
        />
      )}
      <div
        className={classNames('rc-slider-wrapper mb-5 mt-4', { dark: invert })}
      >
        <button
          type="button"
          aria-label="minus"
          className={classNames('rc-slider-button fa fa-minus text-white', {
            'bg-purple': !invert,
          })}
          style={{
            '--fa-secondary-color': '#111c2f',
          }}
          onClick={() => {
            setSliderValue(Math.max(min, sliderValue - step));
            handleInputChange(Math.max(min, value - step));
          }}
        />
        <span
          className={classNames('rc-slider-range rc-slider-range__min', {
            'text-white': invert,
          })}
        >
          {thousandSeperator ? numberToCurrency(parseInt(min, 10)) : `${min}%`}
        </span>
        <Slider
          min={min}
          max={max}
          step={step}
          marks={renderMarkTooltip(value, tooltipNumber, tooltipInfo)}
          handle={format === 'currency' ? handleCurrency : handlePercentage}
          value={typeof value === 'string' ? defaultValue : value}
          onChange={handleInputChange}
          dotStyle={{ display: 'none' }}
        />
        <span
          className={classNames('rc-slider-range rc-slider-range__max', {
            'text-white': invert,
          })}
        >
          {thousandSeperator ? numberToCurrency(parseInt(max, 10)) : `${max}%`}
        </span>
        <button
          type="button"
          aria-label="plus"
          className={classNames('rc-slider-button fa fa-plus text-white', {
            'bg-purple': !invert,
          })}
          style={{
            '--fa-secondary-color': '#111c2f',
          }}
          onClick={() => {
            setSliderValue(Math.min(max, sliderValue + step));
            handleInputChange(Math.min(max, value + step));
          }}
        />
      </div>
      <span
        className={classNames('invalid-feedback text-center', {
          'd-block': hasError,
        })}
      >
        {validationErrors}
      </span>
    </div>
  );
};

RangeSlider.propTypes = {
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  format: PropTypes.string,
  infoLabel: PropTypes.any,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasError: PropTypes.bool.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  tooltipNumber: PropTypes.number,
  tooltipInfo: PropTypes.node,
  step: PropTypes.number.isRequired,
  thousandSeperator: PropTypes.bool,
  invert: PropTypes.bool,
  required: PropTypes.bool,
  uniqueId: PropTypes.string,
};

handleCurrency.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dragging: PropTypes.object,
  thousandSeperator: PropTypes.bool,
};
handlePercentage.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dragging: PropTypes.object,
  thousandSeperator: PropTypes.bool,
};

export default RangeSlider;
