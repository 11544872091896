import styled from 'styled-components';

export const HeroWrapper = styled.div`
  &:before {
    content: '';
    display: block;
    background-color: #e6e7e8;
    background-image:
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(230, 231, 232, 0.25) 400px,
        rgba(57, 68, 78, 0.25) 650px,
        transparent 650px 760px
      ),
      repeating-linear-gradient(
        45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(61, 112, 148, 0.3) -100px,
        rgba(61, 112, 148, 0.2) 150px,
        rgba(61, 112, 148, 0) 220px,
        transparent 220px 330px
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(230, 231, 232, 0.5) 0,
        rgba(57, 68, 78, 0.5) 520px,
        transparent 520px 520px
      );
    opacity: 0.2;
    background-repeat: no-repeat;
    z-index: -1;
    top: 0px;
    right: 0px;
    border-radius: 40%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: absolute;
    top: -200px;
    left: 0;
    right: 0;
    height: 700px;
    transform: scale(1.2);
  }
`;

export const HeroLabel = styled.span`
  font-weight: bold;
  color: #fff;
  border-radius: 50px;
  padding: 0 2vw;
  position: relative;
  display: inline-block;
  z-index: 1;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4b4687;
    z-index: -1;
    border-radius: 50px;
  }
`;
export const HeroImage = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const BGImage = styled.img`
  position: absolute;
  z-index: -1;
  bottom: -10px;
  left: 0px;
  width: 100%;
`;

export const PurpleSectionStyles = styled.section`
  overflow: hidden;
  background-color: #002855;
  position: relative;
  padding: 8rem 0;
  &:before,
  &:after {
    content: '';
    display: block;
    background: #ffffff;
    z-index: 0;
    left: 0;
    right: 0;
    height: 200px;
    transform: scale(1.2);
    position: absolute;
    border-radius: 100%;
  }
  &:before {
    top: -165px;
  }
  &:after {
    top: auto;
    bottom: -165px;
  }
`;
