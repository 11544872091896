export const maxAutoEscalatePercentage = {
  id: 'q6aI1b1a',
  type: 'slider',
  name: 'maxAutoEscalatePercentage',
  paradigmAlias: 'maxAutoEscalatePercentage',
  stepName: 'a_step_11_aca_1',
  step: 1,
  labelClass: 'fs-form-q fw-bold text-center py-3',
  tooltipInfo: '',
  user: {},
  label: 'Select the maximum auto escalate percentage.',
  infoLabel: '',
  format: 'percentage',
  required: true,
  dirty: false,
  disabled: false,
  valueFormatted: '12%',
  value: 12,
  hasConditional: false,
  hasError: false,
  min: 10,
  thousandSeperator: false,
  max: 15,
  defaultValue: 12,
  sideCardTitle: 'Maximum auto escalation percentage',
  reviewScreenTitle: 'Maximum auto escalation percentage',
  optionDescription: '',
  description:
    "Business owners can choose to have their employees' contributions remain fixed unless they change them, or auto-escalate 1% each year up to 10% (15% for QACA option).",
};
