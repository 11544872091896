import PropTypes from 'prop-types';
import React from 'react';

export const ShareRadioButtons = ({
  email,
  handleEmail,
  userRole,
  sponsorEmail,
  advisorEmail,
  tpaEmail,
}) => {
  const sendToLabel = () => {
    switch (userRole) {
      case 'advisor':
        return 'Third-Party-Administrator';
      case 'tpa':
        return 'Financial Professional';
      default:
        return 'TPA or FP';
    }
  };

  return (
    <div data-testid="share-radio-buttons" className="row align-items-center">
      <div className="col-md-auto mx-auto">
        <div className="mb-3 form-group">
          <div className="my-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="option1"
                value="client"
                name="sendToOption1"
                checked={email.value === sponsorEmail}
                onChange={(input) => {
                  if (input.target.checked) {
                    handleEmail(sponsorEmail);
                  }
                }}
              />
              <label className="form-check-label mr-2" htmlFor="option1">
                <strong>To Client</strong>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="option2"
                value="originator"
                name="sendToOption2"
                checked={
                  email.value === tpaEmail || email.value === advisorEmail
                }
                onChange={(input) => {
                  if (input.target.checked) {
                    if (userRole === 'advisor') {
                      handleEmail(tpaEmail);
                    } else {
                      handleEmail(advisorEmail);
                    }
                  }
                }}
              />
              <label className="form-check-label mr-2" htmlFor="option2">
                <strong>To {sendToLabel()}</strong>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShareRadioButtons.propTypes = {
  userRole: PropTypes.string.isRequired,
  email: PropTypes.object.isRequired,
  handleEmail: PropTypes.func.isRequired,
  advisorEmail: PropTypes.string.isRequired,
  tpaEmail: PropTypes.string.isRequired,
  sponsorEmail: PropTypes.string.isRequired,
};
