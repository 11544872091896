export const stretchSafeHarbor = {
  id: 14,
  stepName: 'step_14',
  type: 'radio_button',
  name: 'stretchSafeHarbor',
  user: {
    employer: {
      label: 'Which safe harbor match option do you want?',
      infoLabel:
        'Safe harbor contributions will be 100% vested. If you provide a profit-sharing contribution, it will align with the same vesting schedule. Vesting is the participant’s earned interest in an employer contribution.',
    },
    advisor: {
      label: 'Which safe harbor match option does the business owner want?',
      infoLabel:
        'Safe harbor contributions will be 100% vested. If the business owner provides a profit-sharing contribution, it will align with the same vesting schedule. Vesting is the participant’s earned interest in an employer contribution.',
    },
  },
  options: [
    {
      id: 1,
      value: '4',
      user: {
        employer: {
          label: 'Stretch your match',
          info: 'Match your employee’s contribution:<br /> <strong>Match 100% up to the first 3% of pay</strong><br /> <strong>+</strong><br /> <strong>Match 50% of the next 2% of pay, up to 5%</strong>',
        },
        advisor: {
          label: 'Stretch your match',
          info: '<strong>Match 100% up to the first 3% of pay</strong><br /> <strong>+</strong><br /> <strong>Match 50% of the next 2% of pay, up to 5%</strong>',
        },
      },
      valueFormatted: 'Stretch your match',
      checked: false,
      icon: '',
      hasInfo: true,
      customStyle: '',
    },
    {
      id: 2,
      value: '5',
      user: {
        employer: {
          label: 'Straight match',
          info: 'Match your employee’s contribution:<br /> <strong>Up to 4% of pay: Match 100%</strong>',
        },
        advisor: {
          label: 'Straight match',
          info: '<strong>Up to 4% of pay: Match 100%</strong>',
        },
      },
      valueFormatted: 'Straight match',
      checked: false,
      icon: '',
      customStyle: '',
    },
    {
      id: 3,
      value: '8',
      user: {
        employer: {
          label: 'Qualified Non-Elective Contribution (QNEC): 3%',
          info: "Employer must contribute at least 3 percent of an eligible employee's compensation to the plan, regardless of whether the employee contributes anything",
        },
        advisor: {
          label: 'Qualified Non-Elective Contribution (QNEC): 3%',
          info: "Employer must contribute at least 3 percent of an eligible employee's compensation to the plan, regardless of whether the employee contributes anything",
        },
      },
      valueFormatted: '3% Non-elective',
      checked: false,
      icon: '',
      customStyle: '',
    },
  ],
  required: true,
  dirty: false,
  disabled: false,
  hasConditional: false,
  hasError: false,
  valueFormatted: '3%',
  value: 3,
  description: '',
};
