import { isEnrollmentOptionalAndIsEnrolled } from '../../../Services/stepFlowUtilities';

export const disableDeferralPercentage = (questions, questionId) => {
  const { autoDeferralType, hasTpa } = questions;
  if (isEnrollmentOptionalAndIsEnrolled(questions)) return false;

  return (
    !hasTpa.value &&
    autoDeferralType.value === 'FLAT' &&
    questionId === 'minimumAutoDeferralPercentage'
  );
};

export const disableAutoEnrollment = (questions, questionId) => {
  const { autoEnrollment, hasTpa, businessAging, numberOfEmployees } =
    questions;

  if (questionId === autoEnrollment.name) {
    if (hasTpa.value) return false;
    if (parseInt(numberOfEmployees.value, 10) <= 10) return false;
    if (businessAging.value === 'More than 3 years') return true;
  }

  return false;
};
