export const averageSalary = {
  id: 8,
  stepName: 'step_3',
  type: 'slider',
  name: 'averageSalary',
  valuePrefix: '$',
  labelClass: 'fs-form-q fw-bold text-center py-3',
  user: {
    employer: {
      label: `What's the estimated average annual pay for an employee at your business?`,
      infoLabel:
        'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if you choose to make matching contributions. If you aren’t sure what your employees’ average salary is, just use the pre-filled amount.*',
    },
    advisor: {
      label: 'What is the estimated average annual pay for an employee?',
      infoLabel:
        'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if your client chooses to make matching contributions. If you aren’t sure what the average employee’s salary is, use the pre-filled amount.*',
    },
    tpa: {
      label:
        'What’s the estimated average annual pay for an employee at your client’s business?',
      infoLabel:
        'Don’t worry; a ballpark number is fine. This will only be used to estimate costs if your client chooses to make matching contributions. If you aren’t sure what the average employee’s salary is, use the pre-filled amount.*',
    },
  },
  placeholder: 'Average salary/year',
  hasInfo: false,
  format: 'currency',
  required: true,
  dirty: false,
  disabled: false,
  min: 20000,
  max: 150000,
  step: 5000,
  value: 50000,
  valueFormatted: '$50,000',
  defaultValue: 50000,
  hasConditional: false,
  thousandSeperator: true,
  hasError: false,
  sideCardTitle: 'Average annual pay per employee',
  reviewScreenTitle: 'Average annual pay per employee',
  optionDescription: '',
  description:
    'This will only be used to estimate costs if the business owner chooses to make matching contributions.',
  disclaimer:
    '*According to the Bureau of Labor statistics, the median weekly earnings of full-time workers in the U.S. were $936 in the fourth quarter of 2019 (multiplied by 52 weeks for a total of $48,672 per year). Source: https://www.bls.gov/news.release/pdf/wkyeng.pdf',
};
