import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Question, StepControls } from '../../../common/FormWithSteps';
import { pushGoogleDataLayer } from '../../../common/FormWithSteps/Utilities';
import FormDataRedux, { isFormValid } from '../../../Redux/FormDataRedux';
import { isAutoEnrollmentOptional } from '../../../Services/stepFlowUtilities';
import { originalAutoDeferralType } from '../../../Redux/FormDataRedux/InitialState/DefaultQuestions/originalAutoDeferralType';
import QuestionWrapper from '../../../common/FormWithSteps/QuestionWrapper';

const AutoEnrollment = ({
  role,
  active,
  questionId,
  destination,
  previousStep,
  questions,
  updateActiveStep,
  updateQuestion,
  updateQuestionById,
  formInReview,
  goToReviewPlan,
  googleData,
  resetUnusedAutoEnrollment,
  putApplicationPanelInfo,
  isValid,
}) => {
  const primaryQuestion = questions[questionId];
  const toggleInfoPanel = () => {
    putApplicationPanelInfo({
      hasVisibility: true,
      heading: primaryQuestion.heading,
      additionalInformation: primaryQuestion.content,
    });
  };
  const { value, dirty, hasError } = questions[questionId];
  const validate = !!(dirty && hasError);

  const updateAutoDeferralTypeToOldState = () => {
    updateQuestion({
      ...originalAutoDeferralType,
      stepName: 'step_10',
    });
  };

  const handleQuestionChange = (id, updateValue, valueFormatted) => {
    updateQuestionById(
      id,
      updateValue,
      updateValue === '',
      true,
      valueFormatted,
    );

    if (isAutoEnrollmentOptional(questions) && updateValue === 'yes')
      updateAutoDeferralTypeToOldState();
  };
  useEffect(() => {
    pushGoogleDataLayer(googleData);
  }, []);

  useEffect(() => {
    resetUnusedAutoEnrollment(primaryQuestion.value);
  }, [primaryQuestion.value]);

  return (
    <div className="container-fluid h-100">
      <QuestionWrapper>
        <Question
          role={role}
          type={questions[questionId].type}
          labelClass="fs-form-q fw-bold text-center mb-4"
          inputClass="srp-form-input form-control"
          radioWrapper="srp-form-toggle"
          thousandSeparator
          question={questions[questionId]}
          handleQuestionChange={handleQuestionChange}
          hasInfo={!!questions[questionId].heading}
          toggleInfoPanel={toggleInfoPanel}
        />
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
        {questions[questionId].heading && (
          <p className="text-center mb-lg-auto">
            <button
              type="button"
              className="srp-button srp-button__link mx-auto"
              onClick={() => {
                toggleInfoPanel();
              }}
            >
              Need clarification?
            </button>
          </p>
        )}
        <StepControls
          formInReview={formInReview}
          disableReviewBtn={!isValid}
          disabledNextBtn={
            questions[questionId].required ? !(dirty && !hasError) : false
          }
          goToReviewPlan={goToReviewPlan}
          clickNextBtn={() => {
            updateActiveStep(destination(value), 'forward');
          }}
        />
      </QuestionWrapper>
      <button
        type="button"
        className={`goback fa-solid fa-arrow-left ${active ? 'active' : ''}`}
        onClick={() => {
          updateActiveStep(previousStep, 'back');
        }}
      >
        <span className="sr-only">Go Back</span>
      </button>
    </div>
  );
};

AutoEnrollment.propTypes = {
  role: PropTypes.string,
  active: PropTypes.bool,
  questionId: PropTypes.string,
  destination: PropTypes.any,
  previousStep: PropTypes.string,
  questions: PropTypes.object,
  updateActiveStep: PropTypes.func,
  updateQuestion: PropTypes.func,
  updateQuestionById: PropTypes.func,
  formInReview: PropTypes.bool,
  goToReviewPlan: PropTypes.func,
  googleData: PropTypes.object,
  resetUnusedAutoEnrollment: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  isValid: isFormValid(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (obj) => {
    dispatch(FormDataRedux.updateQuestion(obj));
  },
  resetUnusedAutoEnrollment: (value) => {
    dispatch(FormDataRedux.resetUnusedAutoEnrollment(value));
  },
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoEnrollment);
